import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Box, Typography, useMediaQuery, useTheme, Stack } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import { EventInput, EventContentArg, EventClickArg } from "@fullcalendar/core";
import { RootState } from "../../redux/store";
import {
  Class,
  Schedule,
  Attendee,
  AttendeeToSchedule,
} from "../../redux/types/types";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider";
import EventDialog from "./EventDialog/EventDialog";
import { getClassStatusForDate } from "../Attendance/Attendance";

interface ExtendedEventInput extends Omit<EventInput, "title"> {
  id: string;
  title: string;
  start: Date;
  end: Date;
  extendedProps: {
    classId: number;
    location: string;
    type: string;
    attendees: Attendee[];
    schedule: Schedule;
    status: "Active" | "InProgressOfCancellation" | "Cancelled";
    statusHistory: Array<{
      id: number;
      classId: number;
      status: "Active" | "InProgressOfCancellation" | "Cancelled";
      date: string;
      createdAt: string;
    }>;
  };
}

export const getActiveAttendees = (
  date: Date,
  schedule: Schedule
): Attendee[] => {
  const activeAttendees = new Map<number, Attendee>();

  (schedule.AttendeeToSchedule || []).forEach((ats: AttendeeToSchedule) => {
    const createdAt = new Date(ats.createdAt);
    const unenrollDate = ats.unenrollDate ? new Date(ats.unenrollDate) : null;

    if (createdAt <= date && (!unenrollDate || unenrollDate > date)) {
      if (
        !activeAttendees.has(ats.attendee.id) ||
        createdAt > new Date(activeAttendees.get(ats.attendee.id)!.createdAt!)
      ) {
        activeAttendees.set(ats.attendee.id, {
          ...ats.attendee,
          absences: ats.AttendeeAbsence,
        });
      }
    }
  });

  return Array.from(activeAttendees.values());
};

export default function MySchedules() {
  const intl = useIntl();
  const user = useSelector((state: RootState) => state.auth.user);
  const isInstructor = useSelector(
    (state: RootState) => state.auth.isInstructor
  );
  const { language } = useLanguage();
  const [events, setEvents] = useState<ExtendedEventInput[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<ExtendedEventInput | null>(
    null
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getLocalizedValue = (
    obj: Record<string, string>,
    lang: string
  ): string => {
    return obj[lang] || obj["en"] || "";
  };

  const generateColor = (id: number): string => {
    const hue = (id * 137.508) % 360;
    return theme.palette.mode === "dark"
      ? `hsl(${hue}, 70%, 35%)`
      : `hsl(${hue}, 70%, 65%)`;
  };

  const generateAttendeeColor = (id: number): string => {
    const hue = (id * 137.508) % 360;
    return theme.palette.mode === "dark"
      ? `hsl(${hue}, 100%, 25%)`
      : `hsl(${hue}, 100%, 75%)`;
  };

  useEffect(() => {
    const newEvents: ExtendedEventInput[] = user.classes.flatMap(
      (classItem: Class) => {
        const classStartDate = classItem.startDate
          ? new Date(classItem.startDate)
          : null;
        const classCancelDate = classItem.cancelDate
          ? new Date(classItem.cancelDate)
          : null;

        return classItem.schedules.flatMap(
          (schedule: Schedule): ExtendedEventInput[] => {
            const startTime = new Date(`1970-01-01T${schedule.startTime}:00`);
            const endTime = new Date(`1970-01-01T${schedule.endTime}:00`);

            if (schedule.repeatWeekly) {
              const event: ExtendedEventInput = {
                id: `${classItem.id}-${schedule.id}`,
                title: getLocalizedValue(classItem.title, language),
                daysOfWeek: [(schedule.dayOfWeek + 1) % 7],
                startTime: schedule.startTime,
                endTime: schedule.endTime,
                startRecur: classStartDate || undefined,
                endRecur: classCancelDate || undefined,
                start: startTime,
                end: endTime,
                color: generateColor(classItem.id),
                extendedProps: {
                  location: getLocalizedValue(
                    classItem.location.name,
                    language
                  ),
                  type: getLocalizedValue(classItem.type.name, language),
                  classId: classItem.id,
                  attendees: schedule.attendees || [],
                  schedule,
                  status: classItem.status || "Active",
                  statusHistory: Array.isArray(classItem.statusHistory)
                    ? classItem.statusHistory
                    : [],
                },
              };
              return [event];
            } else if (schedule.date) {
              const eventDate = new Date(schedule.date);
              eventDate.setHours(startTime.getHours(), startTime.getMinutes());
              const eventEndDate = new Date(schedule.date);
              eventEndDate.setHours(endTime.getHours(), endTime.getMinutes());

              if (!classStartDate || eventDate >= classStartDate) {
                return [
                  {
                    id: `${classItem.id}-${schedule.id}`,
                    title: getLocalizedValue(classItem.title, language),
                    start: eventDate,
                    end: eventEndDate,
                    color: generateColor(classItem.id),
                    extendedProps: {
                      location: getLocalizedValue(
                        classItem.location.name,
                        language
                      ),
                      type: getLocalizedValue(classItem.type.name, language),
                      classId: classItem.id,
                      attendees: schedule.attendees || [],
                      schedule,
                      status: classItem.status || "Active",
                      statusHistory: Array.isArray(classItem.statusHistory)
                        ? classItem.statusHistory
                        : [],
                    },
                  },
                ];
              }
            }
            return [];
          }
        );
      }
    );

    setEvents(newEvents);
    //eslint-disable-next-line
  }, [user.classes, language, theme.palette.mode]);

  const renderEventContent = (eventInfo: EventContentArg) => {
    const view = eventInfo.view.type;
    const isMonthView = view === "dayGridMonth";

    const attendeesList =
      eventInfo.event.extendedProps.schedule.AttendeeToSchedule.filter(
        (ats: AttendeeToSchedule) =>
          eventInfo.event.start &&
          new Date(ats.createdAt) < new Date(eventInfo.event.start) &&
          (!ats.unenrollDate ||
            new Date(ats.unenrollDate) > new Date(eventInfo.event.start))
      );

    const status = eventInfo.event.start
      ? getClassStatusForDate(
          eventInfo.event.extendedProps.statusHistory,
          eventInfo.event.start
        )
      : "Active";

    if (!isInstructor && (!attendeesList.length || status === "Cancelled")) {
      return null;
    }

    if (status === "Cancelled") {
      return null;
    }

    return (
      <Box
        sx={{
          fontSize: isMonthView ? "0.7rem" : "0.8rem",
          lineHeight: 1.2,
          padding: "2px 4px",
          borderRadius: "4px",
          backgroundColor: eventInfo.backgroundColor,
          color: theme.palette.getContrastText(
            eventInfo.backgroundColor as string
          ),
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          overflow: "hidden",
        }}
      >
        <Typography variant="caption" sx={{ fontWeight: "bold" }}>
          {isMonthView
            ? `${eventInfo.event.startStr.slice(
                11,
                16
              )} - ${eventInfo.event.endStr.slice(11, 16)}`
            : eventInfo.timeText}
        </Typography>
        <Typography variant="caption" sx={{ fontWeight: "bold" }}>
          {eventInfo.event.title}
        </Typography>
        {!isMonthView && (
          <>
            <Typography variant="caption" sx={{ display: "block", mt: 0.5 }}>
              {eventInfo.event.extendedProps.location}
            </Typography>
            <Typography variant="caption" sx={{ display: "block" }}>
              {eventInfo.event.extendedProps.type}
            </Typography>
          </>
        )}
        {!isInstructor && (
          <Stack direction="row" spacing={0.5} mt={0.5}>
            {attendeesList.map((ats: AttendeeToSchedule) => (
              <Box
                key={ats.attendee.id}
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  backgroundColor: generateAttendeeColor(ats.attendee.id),
                }}
              />
            ))}
          </Stack>
        )}
      </Box>
    );
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    const eventData = clickInfo.event.toPlainObject() as ExtendedEventInput;
    setSelectedEvent({
      ...eventData,
      id:
        eventData.id ||
        `${eventData.extendedProps.classId}-${clickInfo.event.id}`,
      title: eventData.title || "",
      start: clickInfo.event.start || new Date(),
      end: clickInfo.event.end || new Date(),
      extendedProps: {
        ...eventData.extendedProps,
        attendees: eventData.extendedProps.attendees || [],
      },
    });
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box sx={{ height: "100%", p: isMobile ? 1 : 3 }}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView={isMobile ? "listWeek" : "dayGridMonth"}
        headerToolbar={{
          left: isMobile ? "prev,next" : "prev,next today",
          center: "title",
          right: isMobile
            ? "listWeek,timeGridDay"
            : "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        locale={language}
        locales={allLocales}
        buttonText={{
          today: intl.formatMessage({
            id: "calendar.today",
            defaultMessage: "Today",
          }),
          month: intl.formatMessage({
            id: "calendar.month",
            defaultMessage: "Month",
          }),
          week: intl.formatMessage({
            id: "calendar.week",
            defaultMessage: "Week",
          }),
          day: intl.formatMessage({
            id: "calendar.day",
            defaultMessage: "Day",
          }),
          list: intl.formatMessage({
            id: "calendar.list",
            defaultMessage: "List",
          }),
        }}
        titleFormat={(args) => {
          const date = new Date(args.date.year, args.date.month, args.date.day);

          if (
            args.end?.marker &&
            new Date(args.start.marker).getMonth() !==
              new Date(args.end?.marker).getMonth() &&
            Math.abs(
              (new Date(args.end?.marker).getTime() -
                new Date(args.start.marker).getTime()) /
                (1000 * 60 * 60 * 24)
            ) < 8 &&
            Math.abs(
              (new Date(args.end?.marker).getTime() -
                new Date(args.start.marker).getTime()) /
                (1000 * 60 * 60 * 24)
            ) > 2
          ) {
            return `${intl.formatDate(date, {
              year: "numeric",
              month: "long",
            })}/${intl.formatDate(new Date(args.end?.marker), {
              year: "numeric",
              month: "long",
            })}`;
          }

          if (
            args.end?.marker &&
            new Date(args.end?.marker).getUTCDate() ===
              new Date(args.start.marker).getUTCDate()
          ) {
            return intl.formatDate(date, {
              year: "numeric",
              month: "long",
              day: "2-digit",
            });
          }

          return intl.formatDate(date, { year: "numeric", month: "long" });
        }}
        events={events}
        eventContent={renderEventContent}
        eventDidMount={(info) => {
          const content = renderEventContent(info);
          if (!content) {
            info.el.style.display = "none";
          }
        }}
        eventClick={handleEventClick}
        height="auto"
        aspectRatio={isMobile ? 0.8 : 1.35}
        firstDay={1}
        stickyHeaderDates={true}
        eventDisplay="block"
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: true,
          meridiem: "short",
        }}
      />

      <EventDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        event={selectedEvent}
      />

      <style>
        {`
          .fc .fc-toolbar-title {
            color: ${theme.palette.text.primary};
          }
          .fc .fc-button {
            background-color: ${theme.palette.primary.main};
            border-color: ${theme.palette.primary.main};
            color: ${theme.palette.primary.contrastText};
          }
          .fc .fc-button:hover {
            background-color: ${theme.palette.primary.dark};
            border-color: ${theme.palette.primary.dark};
          }
          .fc .fc-button-primary:not(:disabled).fc-button-active, 
          .fc .fc-button-primary:not(:disabled):active {
            background-color: ${theme.palette.primary.dark};
            border-color: ${theme.palette.primary.dark};
          }
          .fc-theme-standard .fc-scrollgrid,
          .fc-theme-standard td,
          .fc-theme-standard th {
            border-color: ${theme.palette.divider};
          }
          .fc .fc-daygrid-day.fc-day-today,
          .fc .fc-col-header-cell.fc-day-today {
            background-color: ${theme.palette.action.selected};
          }
          .fc .fc-col-header-cell-cushion,
          .fc .fc-daygrid-day-number,
          .fc .fc-list-event-time,
          .fc .fc-list-event-title {
            color: ${theme.palette.text.primary};
          }
          .fc-theme-standard .fc-list-day-cushion {
            background-color: ${theme.palette.background.paper};
          }
          .fc .fc-list-event:hover td {
            background-color: ${theme.palette.action.hover};
          }
          .fc .fc-list-empty {
            background-color: ${theme.palette.background.paper};
            color: ${theme.palette.text.secondary};
          }
          .fc-day-today .fc-daygrid-day-number {
            font-weight: bold;
          }
          .fc .fc-col-header-cell-cushion,
          .fc .fc-timegrid-axis-cushion,
          .fc .fc-timegrid-slot-label-cushion {
            color: ${theme.palette.text.primary};
          }
          .fc .fc-col-header-cell {
            background-color: ${theme.palette.background.paper};
          }
          .fc .fc-daygrid-day {
            background-color: ${theme.palette.background.default};
          }
          .fc .fc-daygrid-day-top {
            padding: 4px;
          }
          .fc .fc-daygrid-day-number {
            color: ${theme.palette.text.primary};
            font-weight: bold;
          }
          .fc .fc-day-today {
            background-color: ${theme.palette.action.selected} !important;
          }
          .fc-event-title,
          .fc-event-time {
            padding: 1px 2px;
            font-weight: bold;
          }
          .fc-event {
            border: none !important;
          }
          .fc .fc-timegrid-col.fc-day-today,
          .fc .fc-timegrid-col.fc-day-today .fc-timegrid-col-frame {
            background-color: ${theme.palette.action.selected} !important;
          }
          .fc .fc-timegrid-now-indicator-line {
            border-color: ${theme.palette.error.main};
          }
          .fc .fc-timegrid-now-indicator-arrow {
            border-color: ${theme.palette.error.main};
            color: ${theme.palette.error.main};
          }
          .fc .fc-col-header-cell.fc-day.fc-day-today {
            background-color: ${theme.palette.primary.main} !important;
          }
          .fc .fc-col-header-cell.fc-day.fc-day-today .fc-col-header-cell-cushion {
            color: ${theme.palette.primary.contrastText} !important;
          }
          .fc .fc-timegrid-axis {
            background-color: ${
              theme.palette.mode === "dark"
                ? theme.palette.grey[800]
                : theme.palette.background.paper
            };
          }
        `}
      </style>
    </Box>
  );
}
