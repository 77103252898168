import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Grid,
  Autocomplete,
} from "@mui/material";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  getInstructorClassHoursReport,
  fetchInstructors,
  fetchClasses,
  fetchTypes,
} from "../../../../redux/admin/adminOperations";
import {
  Instructor,
  Class,
  InstructorClassHoursReportItem,
  Type,
} from "../../../../redux/types/types";
import * as XLSX from "xlsx";
import {
  Language,
  useLanguage,
} from "../../../LanguageProvider/LanguageProvider";

export default function InstructorHoursReports() {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const { language } = useLanguage();
  const { instructors, classes, loading, types } = useSelector(
    (state: RootState) => state.admin
  );
  const { instructorClassHoursReport } = useSelector(
    (state: RootState) => state.admin.reports
  );
  const [selectedInstructor, setSelectedInstructor] =
    useState<Instructor | null>(null);
  const [selectedClass, setSelectedClass] = useState<Class | null>(null);
  const [selectedType, setSelectedType] = useState<Type | null>(null);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(
      fetchInstructors({
        page: 1,
        limit: 1000,
        sortBy: "id",
        sortOrder: "asc",
        search: "",
      })
    );
    dispatch(
      fetchClasses({
        page: 1,
        limit: 1000,
        sortBy: "id",
        sortOrder: "asc",
        search: "",
      })
    );
    dispatch(
      fetchTypes({
        page: 1,
        limit: 1000,
        sortBy: "id",
        sortOrder: "asc",
        search: "",
      })
    );
  }, [dispatch]);

  const filteredClasses = useMemo(() => {
    if (!selectedInstructor) return classes;
    return classes.filter((c) =>
      c.instructors.find((i) => i.instructor.id === selectedInstructor.id)
    );
  }, [classes, selectedInstructor]);

  const handleInstructorChange = (value: Instructor | null) => {
    setSelectedInstructor(value);
    setSelectedClass(null);
    setSelectedType(null);
  };

  const handleClassChange = (value: Class | null) => {
    setSelectedClass(value);
    setSelectedType(value ? value.type : null);
  };

  const filteredTypes = useMemo(() => {
    if (!selectedInstructor && !selectedClass) return types;
    if (selectedClass) {
      return [selectedClass.type];
    }
    return types.filter((t) => filteredClasses.some((c) => c.type.id === t.id));
  }, [types, filteredClasses, selectedInstructor, selectedClass]);

  const handleGenerateReport = () => {
    dispatch(
      getInstructorClassHoursReport({
        instructorId: selectedInstructor?.id?.toString(),
        classId: selectedClass?.id?.toString(),
        typeId: selectedType?.id?.toString() || undefined,
        fromDate: fromDate || undefined,
        toDate: toDate || undefined,
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedReport = instructorClassHoursReport
    ? instructorClassHoursReport.slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage
      )
    : [];

  const getFormattedDateTime = () => {
    const now = new Date();
    return now.toISOString().replace(/[:.]/g, "-").slice(0, -5);
  };

  const handleExportToExcel = () => {
    if (!instructorClassHoursReport) return;

    const worksheet = XLSX.utils.json_to_sheet(
      instructorClassHoursReport.map(
        (item: InstructorClassHoursReportItem) => ({
          "Instructor Name": `${item.instructor.firstname} ${item.instructor.lastname}`,
          "Instructor Email": item.instructor.email,
          "Instructor Phone": item.instructor.phone,
          "Class Title": item.class.title.fr,
          "Total Hours": item.totalHours,
          "Unique Attendees Count": item.uniqueAttendeesCount,
        })
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Instructor Hours Report"
    );
    const fileName = `instructor_hours_report_${getFormattedDateTime()}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        <FormattedMessage
          id="admin.dashboard.reports.instructorHours.title"
          defaultMessage="Instructor Hours Report"
        />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={instructors}
            getOptionLabel={(option: Instructor) =>
              `${option.firstname} ${option.lastname}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "admin.dashboard.instructor",
                  defaultMessage: "Instructor",
                })}
                fullWidth
              />
            )}
            value={selectedInstructor}
            onChange={(event, newValue) => handleInstructorChange(newValue)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={filteredClasses}
            getOptionLabel={(option: Class) =>
              option.title[language] ||
              option.title[
                process.env.REACT_APP_DEFAULT_LANGUAGE as Language
              ] ||
              option.title.fr ||
              option.title.en ||
              option.title.uk
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "admin.dashboard.class",
                  defaultMessage: "Class",
                })}
                fullWidth
              />
            )}
            value={selectedClass}
            onChange={(event, newValue) => handleClassChange(newValue)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label={intl.formatMessage({
              id: "admin.dashboard.reports.fromDate",
              defaultMessage: "From Date",
            })}
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: new Date().toISOString().split("T")[0],
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label={intl.formatMessage({
              id: "admin.dashboard.reports.toDate",
              defaultMessage: "To Date",
            })}
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: new Date().toISOString().split("T")[0],
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={filteredTypes}
            getOptionLabel={(option: Type) =>
              option.name[language] ||
              option.name[process.env.REACT_APP_DEFAULT_LANGUAGE as Language] ||
              option.name.fr ||
              option.name.en ||
              option.name.uk
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "admin.dashboard.type",
                  defaultMessage: "Type",
                })}
                fullWidth
              />
            )}
            value={selectedType}
            onChange={(event, newValue) => setSelectedType(newValue)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            onClick={handleGenerateReport}
            disabled={loading}
            fullWidth
            sx={{ height: "100%" }}
          >
            <FormattedMessage
              id="admin.dashboard.reports.generate"
              defaultMessage="Generate Report"
            />
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="outlined"
            onClick={handleExportToExcel}
            disabled={
              !instructorClassHoursReport ||
              instructorClassHoursReport.length === 0
            }
            fullWidth
            sx={{ height: "100%" }}
          >
            <FormattedMessage
              id="admin.dashboard.reports.exportExcel"
              defaultMessage="Export to Excel"
            />
          </Button>
        </Grid>
      </Grid>
      {instructorClassHoursReport && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    id="admin.dashboard.instructor"
                    defaultMessage="Instructor"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="admin.dashboard.class"
                    defaultMessage="Class"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="admin.dashboard.reports.instructorHours.totalHours"
                    defaultMessage="Total Hours"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="admin.dashboard.reports.instructorHours.uniqueAttendees"
                    defaultMessage="Unique Attendees"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedReport.map(
                (item: InstructorClassHoursReportItem, index: number) => (
                  <TableRow
                    key={`${item.instructor.id}-${item.class.id}-${index}`}
                  >
                    <TableCell>{`${item.instructor.firstname} ${item.instructor.lastname}`}</TableCell>
                    <TableCell>
                      {item.class.title.fr || item.class.title.en}
                    </TableCell>
                    <TableCell>{item.totalHours}</TableCell>
                    <TableCell>{item.uniqueAttendeesCount}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={instructorClassHoursReport.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={intl.formatMessage({
              id: "admin.dashboard.rowsPerPage",
              defaultMessage: "Rows per page:",
            })}
          />
        </TableContainer>
      )}
    </Box>
  );
}
