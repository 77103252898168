import React from "react";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPostalCode } from "postal-code-validator";
import { AppDispatch } from "../../../../../../redux/store";
import { createAttendeeAdm } from "../../../../../../redux/enrollment/enrollmentOperations";
import LatinTextField from "../../../../../../helpers/latinTextField";
import PhoneField from "../../../../../../helpers/PhoneField";
import EmailTextField from "../../../../../../helpers/EmailTextField";
import { User } from "../../../../../../redux/types/types";
import { fetchAttendeesForUser } from "../../../../../../redux/admin/adminOperations";

interface CreateAttendeeFormProps {
  open: boolean;
  onClose: () => void;
  user: User;
}

export default function CreateAttendeeForm({
  open,
  onClose,
  user,
}: CreateAttendeeFormProps) {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      intl.formatMessage({ id: "signUp.firstNameRequired" })
    ),
    lastName: Yup.string().required(
      intl.formatMessage({ id: "signUp.lastNameRequired" })
    ),
    birthdate: Yup.date().required(
      intl.formatMessage({ id: "privateEnroll.birthdateRequired" })
    ),
    sex: Yup.string().required(
      intl.formatMessage({ id: "privateEnroll.sexRequired" })
    ),
    phone: Yup.string()
      .required(intl.formatMessage({ id: "signUp.phoneRequired" }))
      .test(
        "is-valid-phone-number",
        intl.formatMessage({ id: "signUp.invalidPhoneNumber" }),
        (value) => isValidPhoneNumber(value)
      ),
    email: Yup.string()
      .email(intl.formatMessage({ id: "signUp.invalidEmail" }))
      .required(intl.formatMessage({ id: "signUp.emailRequired" })),
    postalCode: Yup.string()
      .test(
        "is-valid-postal-code",
        intl.formatMessage({ id: "attendees.invalidPostalCodeFormat" }),
        (value) => {
          if (!value) return false;
          return isValidPostalCode(value);
        }
      )
      .required(intl.formatMessage({ id: "signUp.postalCodeRequired" })),
    permisStatus: Yup.string().required(
      intl.formatMessage({ id: "attendee.permisStatus.required" })
    ),
  });

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      if (typeof user.id === "number")
        await dispatch(
          createAttendeeAdm({
            attendee: {
              ...values,
              isUser:
                values.email === user.email &&
                values.firstName === user.firstname &&
                values.lastName === user.lastname,
              birthdate: new Date(values.birthdate),
            },
            userId: user.id,
          })
        )
          .unwrap()
          .then(async () => {
            if (user.id) await dispatch(fetchAttendeesForUser(user.id));
          });
      onClose();
      resetForm();
    } catch (error) {
      console.error("Failed to create attendee:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <FormattedMessage
          id="attendees.createNew"
          defaultMessage="Create New Attendee"
        />
      </DialogTitle>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          birthdate: "",
          sex: "",
          phone: "",
          email: "",
          postalCode: "",
          permisStatus: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={LatinTextField}
                    name="firstName"
                    label={intl.formatMessage({ id: "signUp.firstName.input" })}
                    error={touched.firstName && errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={LatinTextField}
                    name="lastName"
                    label={intl.formatMessage({ id: "signUp.lastName.input" })}
                    error={touched.lastName && errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="birthdate"
                    label={intl.formatMessage({
                      id: "admin.dashboard.birthdate",
                    })}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: new Date().toISOString().split("T")[0] }}
                    error={touched.birthdate && errors.birthdate}
                    helperText={touched.birthdate && errors.birthdate}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" required>
                      {intl.formatMessage({ id: "admin.dashboard.sex" })}
                    </FormLabel>
                    <Field as={RadioGroup} name="sex" row>
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label={intl.formatMessage({
                          id: "privateEnroll.female",
                        })}
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label={intl.formatMessage({
                          id: "privateEnroll.male",
                        })}
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label={intl.formatMessage({
                          id: "privateEnroll.other",
                        })}
                      />
                    </Field>
                    <ErrorMessage name="sex">
                      {(msg) => (
                        <Typography
                          color="error"
                          variant="caption"
                          component="div"
                          style={{ marginTop: 4 }}
                        >
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Field
                    name="phone"
                    component={PhoneField}
                    form={{
                      errors,
                      touched,
                      setFieldValue,
                      values,
                    }}
                    label={intl.formatMessage({ id: "admin.dashboard.phone" })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={EmailTextField}
                    name="email"
                    label={intl.formatMessage({ id: "signUp.email" })}
                    type="email"
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="postalCode"
                    label={intl.formatMessage({ id: "signUp.postalCode" })}
                    error={touched.postalCode && errors.postalCode}
                    helperText={touched.postalCode && errors.postalCode}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    required
                    error={touched.permisStatus && Boolean(errors.permisStatus)}
                  >
                    <InputLabel id="permisStatus-label">
                      {intl.formatMessage({
                        id: "attendee.permisStatus",
                      })}
                    </InputLabel>
                    <Select
                      labelId="permisStatus-label"
                      id="permisStatus"
                      name="permisStatus"
                      label={intl.formatMessage({
                        id: "attendee.permisStatus",
                      })}
                      value={values.permisStatus}
                      onChange={(e) => {
                        setFieldValue("permisStatus", e.target.value);
                      }}
                    >
                      <MenuItem value="PermisS">
                        {intl.formatMessage({
                          id: "attendee.permisStatus.permisS",
                        })}
                      </MenuItem>
                      <MenuItem value="Other">
                        {intl.formatMessage({
                          id: "attendee.permisStatus.other",
                        })}
                      </MenuItem>
                    </Select>
                    {touched.permisStatus && errors.permisStatus && (
                      <FormHelperText>{errors.permisStatus}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
              </Button>
              <Button type="submit" variant="contained" color="primary">
                <FormattedMessage id="common.create" defaultMessage="Create" />
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
