import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { User } from "../../../../redux/types/types";
import LatinTextField from "../../../../helpers/latinTextField";
import EmailTextField from "../../../../helpers/EmailTextField";
import PhoneField from "../../../../helpers/PhoneField";

interface EditUserDialogProps {
  open: boolean;
  userToEdit: User | null;
  onClose: () => void;
  onSave: (user: User) => Promise<void>;
  error: string | null;
}

export default function EditUserDialog({
  open,
  userToEdit,
  onClose,
  onSave,
  error,
}: EditUserDialogProps) {
  const intl = useIntl();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(intl.formatMessage({ id: "admin.dashboard.invalidEmail" }))
      .required(intl.formatMessage({ id: "admin.dashboard.emailRequired" })),
    firstname: Yup.string()
      .required(intl.formatMessage({ id: "admin.dashboard.firstnameRequired" }))
      .min(2, intl.formatMessage({ id: "admin.dashboard.firstnameMinLength" }))
      .max(
        50,
        intl.formatMessage({ id: "admin.dashboard.firstnameMaxLength" })
      ),
    lastname: Yup.string()
      .required(intl.formatMessage({ id: "admin.dashboard.lastnameRequired" }))
      .min(2, intl.formatMessage({ id: "admin.dashboard.lastnameMinLength" }))
      .max(50, intl.formatMessage({ id: "admin.dashboard.lastnameMaxLength" })),
    phone: Yup.string()
      .required(intl.formatMessage({ id: "admin.dashboard.phoneRequired" }))
      .test(
        "is-valid-phone-number",
        intl.formatMessage({ id: "signUp.invalidPhoneNumber" }),
        (value) => isValidPhoneNumber(value)
      ),
    dataUsageAgreed: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({ id: "signUp.dataUsageAgreed.required" })
    ),
  });

  const handleSubmit = async (values: User) => {
    await onSave(values);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown
      onBackdropClick={(event) => event.stopPropagation()}
    >
      <DialogTitle id="form-dialog-title">
        <FormattedMessage id="admin.dashboard.editUser" />
      </DialogTitle>
      <Formik
        initialValues={
          userToEdit || {
            email: "",
            firstname: "",
            lastname: "",
            phone: "",
            dataUsageAgreed: true,
            photoAgreed: false,
            id: 0,
            role: "user",
            attendees: [],
            classes: [],
          }
        }
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, setFieldValue, values }) => (
          <Form>
            <DialogContent>
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  <FormattedMessage id={error} defaultMessage={error} />
                </Alert>
              )}
              <Field
                as={EmailTextField}
                margin="dense"
                fullWidth
                id="email"
                name="email"
                label={intl.formatMessage({ id: "admin.dashboard.email" })}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
              <Field
                as={LatinTextField}
                margin="dense"
                fullWidth
                id="firstname"
                name="firstname"
                label={intl.formatMessage({
                  id: "admin.dashboard.firstname.input",
                })}
                error={touched.firstname && !!errors.firstname}
                helperText={touched.firstname && errors.firstname}
              />
              <Field
                as={LatinTextField}
                margin="dense"
                fullWidth
                id="lastname"
                name="lastname"
                label={intl.formatMessage({
                  id: "admin.dashboard.lastname.input",
                })}
                error={touched.lastname && !!errors.lastname}
                helperText={touched.lastname && errors.lastname}
              />
              <Field
                name="phone"
                component={PhoneField}
                form={{ errors, touched, isSubmitting, setFieldValue, values }}
                label={intl.formatMessage({ id: "admin.dashboard.phone" })}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                <FormattedMessage id="admin.dashboard.cancel" />
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                <FormattedMessage id="admin.dashboard.save" />
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
