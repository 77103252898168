import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Language } from "../../../LanguageProvider/LanguageProvider";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import EmailTextField from "../../../../helpers/EmailTextField";
import PhoneField from "../../../../helpers/PhoneField";

interface LanguageFields {
  en: string;
  uk: string;
  fr: string;
}

interface Location {
  id: number;
  name: LanguageFields;
  address: LanguageFields;
  email: string;
  phone: string;
}

interface EditLocationDialogProps {
  open: boolean;
  locationToEdit: Location | null;
  onClose: () => void;
  onSave: (updatedLocation: Location) => void;
}

export default function EditLocationDialog({
  open,
  locationToEdit,
  onClose,
  onSave,
}: EditLocationDialogProps) {
  const intl = useIntl();
  const [currentTab, setCurrentTab] = useState<"en" | "uk" | "fr">(
    process.env.REACT_APP_DEFAULT_LANGUAGE as "en" | "uk" | "fr"
  );

  const validationSchema = Yup.object({
    name: Yup.object({
      [process.env.REACT_APP_DEFAULT_LANGUAGE as string]: Yup.string().required(
        intl.formatMessage({ id: "admin.dashboard.nameRequired" })
      ),
    }),
    address: Yup.object({
      [process.env.REACT_APP_DEFAULT_LANGUAGE as string]: Yup.string().required(
        intl.formatMessage({ id: "admin.dashboard.addressRequired" })
      ),
    }),
    email: Yup.string()
      .email(intl.formatMessage({ id: "admin.dashboard.invalidEmail" }))
      .required(intl.formatMessage({ id: "admin.dashboard.emailRequired" })),
    phone: Yup.string()
      .required(intl.formatMessage({ id: "admin.dashboard.phoneRequired" }))
      .test(
        "is-valid-phone-number",
        intl.formatMessage({ id: "signUp.invalidPhoneNumber" }),
        (value) => isValidPhoneNumber(value)
      ),
  });

  if (!locationToEdit) {
    return null;
  }

  const handleSubmit = (values: Location) => {
    onSave(values);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        <FormattedMessage id="admin.dashboard.editLocation" />
      </DialogTitle>
      <Formik
        initialValues={locationToEdit}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, setFieldValue, values }) => (
          <Form>
            <DialogContent>
              <Tabs
                value={currentTab}
                onChange={(_, newValue: "en" | "uk" | "fr") =>
                  setCurrentTab(newValue)
                }
                aria-label="language tabs"
              >
                <Tab
                  label={intl.formatMessage({
                    id:
                      (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" &&
                        "common.english") ||
                      (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" &&
                        "common.french") ||
                      "",
                  })}
                  value={process.env.REACT_APP_DEFAULT_LANGUAGE}
                />
                <Tab
                  label={intl.formatMessage({ id: "common.ukrainian" })}
                  value="uk"
                  disabled={
                    values.name[
                      process.env.REACT_APP_DEFAULT_LANGUAGE as Language
                    ].trim() === "" ||
                    values.address[
                      process.env.REACT_APP_DEFAULT_LANGUAGE as Language
                    ].trim() === ""
                  }
                />
                <Tab
                  label={intl.formatMessage({
                    id:
                      (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" &&
                        "common.french") ||
                      (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" &&
                        "common.english") ||
                      "",
                  })}
                  value={
                    (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" && "en") ||
                    (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" && "fr")
                  }
                  disabled={
                    values.name[
                      process.env.REACT_APP_DEFAULT_LANGUAGE as Language
                    ].trim() === "" ||
                    values.address[
                      process.env.REACT_APP_DEFAULT_LANGUAGE as Language
                    ].trim() === ""
                  }
                />
              </Tabs>
              <Box sx={{ mt: 2 }}>
                {currentTab === "en" && (
                  <>
                    <Field
                      as={TextField}
                      margin="dense"
                      id="name-en"
                      name="name.en"
                      label={intl.formatMessage({
                        id: "admin.dashboard.nameEn",
                      })}
                      type="text"
                      fullWidth
                      error={touched.name?.en && !!errors.name?.en}
                      helperText={touched.name?.en && errors.name?.en}
                      required={
                        (process.env.REACT_APP_DEFAULT_LANGUAGE as string) ===
                        "en"
                      }
                    />
                    <Field
                      as={TextField}
                      margin="dense"
                      id="address-en"
                      name="address.en"
                      label={intl.formatMessage({
                        id: "admin.dashboard.addressEn",
                      })}
                      type="text"
                      fullWidth
                      error={touched.address?.en && !!errors.address?.en}
                      helperText={touched.address?.en && errors.address?.en}
                      required={
                        (process.env.REACT_APP_DEFAULT_LANGUAGE as string) ===
                        "en"
                      }
                    />
                  </>
                )}
                {currentTab === "uk" && (
                  <>
                    <Field
                      as={TextField}
                      margin="dense"
                      id="name-uk"
                      name="name.uk"
                      label={intl.formatMessage({
                        id: "admin.dashboard.nameUk",
                      })}
                      type="text"
                      fullWidth
                    />
                    <Field
                      as={TextField}
                      margin="dense"
                      id="address-uk"
                      name="address.uk"
                      label={intl.formatMessage({
                        id: "admin.dashboard.addressUk",
                      })}
                      type="text"
                      fullWidth
                    />
                  </>
                )}
                {currentTab === "fr" && (
                  <>
                    <Field
                      as={TextField}
                      margin="dense"
                      id="name-fr"
                      name="name.fr"
                      label={intl.formatMessage({
                        id: "admin.dashboard.nameFr",
                      })}
                      type="text"
                      fullWidth
                      required={
                        (process.env.REACT_APP_DEFAULT_LANGUAGE as string) ===
                        "fr"
                      }
                    />
                    <Field
                      as={TextField}
                      margin="dense"
                      id="address-fr"
                      name="address.fr"
                      label={intl.formatMessage({
                        id: "admin.dashboard.addressFr",
                      })}
                      type="text"
                      fullWidth
                      required={
                        (process.env.REACT_APP_DEFAULT_LANGUAGE as string) ===
                        "fr"
                      }
                    />
                  </>
                )}
              </Box>
              <Field
                as={EmailTextField}
                margin="dense"
                id="email"
                name="email"
                label={intl.formatMessage({ id: "admin.dashboard.email" })}
                type="email"
                fullWidth
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
              <Field
                name="phone"
                component={PhoneField}
                form={{ errors, touched, isSubmitting, setFieldValue, values }}
                label={intl.formatMessage({ id: "admin.dashboard.phone" })}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                <FormattedMessage id="admin.dashboard.cancel" />
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting}>
                <FormattedMessage id="admin.dashboard.save" />
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
