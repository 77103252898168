import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  SelectChangeEvent,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  fetchLocations,
  fetchTypes,
  fetchInstructors,
} from "../../../../redux/admin/adminOperations";
import ScheduleManager from "../ScheduleManager/ScheduleManager";
import Editor from "../../../../utils/styles/StyledEditor";
import { Class, Instructor } from "../../../../redux/types/types";
import ClassImages from "../ClassImages/ClassImages";
import Notiflix from "notiflix";
import { Language } from "../../../LanguageProvider/LanguageProvider";

interface EditClassDialogProps {
  open: boolean;
  classToEdit: Class | null;
  onClose: () => void;
  onSave: (
    updatedClass: Omit<Class, "instructors"> & {
      instructors: Instructor[];
    }
  ) => void;
}

export default function EditClassDialog({
  open,
  classToEdit,
  onClose,
  onSave,
}: EditClassDialogProps) {
  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const [currentTab, setCurrentTab] = useState<"en" | "uk" | "fr">(
    process.env.REACT_APP_DEFAULT_LANGUAGE as "en" | "uk" | "fr"
  );
  const [dialogTab, setDialogTab] = useState<"class" | "schedule" | "images">(
    "class"
  );
  const locations = useSelector((state: RootState) => state.admin.locations);
  const types = useSelector((state: RootState) => state.admin.types);
  const instructors = useSelector(
    (state: RootState) => state.admin.instructors
  );
  const [isLoading, setIsLoading] = useState(false);
  const [disDefaultLangEmpty, setIsDefaultLangEmpty] = useState(false);
  const [localClass, setLocalClass] = useState<
    | (Omit<Class, "startDate" | "instructors"> & {
        startDate: string | null;
        instructors: Instructor[];
      })
    | null
  >(null);

  const [errors, setErrors] = useState<{
    title?: string;
    description?: string;
    location?: string;
    type?: string;
    instructors?: string;
    maxAttendees?: string;
    startDate?: string;
  }>({});

  const defaultLang = process.env.REACT_APP_DEFAULT_LANGUAGE as Language;

  useEffect(() => {
    if (classToEdit) {
      setLocalClass({
        ...classToEdit,
        instructors: classToEdit.instructors.map((i) => i.instructor),
        startDate: classToEdit.startDate
          ? new Date(classToEdit.startDate).toISOString().split("T")[0]
          : "",
      });
    }
  }, [classToEdit]);

  useEffect(() => {
    setIsDefaultLangEmpty(
      localClass?.title[defaultLang].trim() === "" ||
        localClass?.description[defaultLang].trim() === ""
    );
  }, [defaultLang, localClass?.title, localClass?.description]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      Promise.all([
        dispatch(
          fetchLocations({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
        dispatch(
          fetchTypes({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
        dispatch(
          fetchInstructors({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
      ]).then(() => {
        setIsLoading(false);
      });
    }
  }, [open, dispatch]);

  const handleTitleChange = (value: string, lang: "en" | "uk" | "fr") => {
    if (localClass) {
      setLocalClass({
        ...localClass,
        title: {
          ...localClass.title,
          [lang]: value,
        },
      });

      if (lang === defaultLang && errors.title) {
        setErrors((prevErrors) => ({ ...prevErrors, title: undefined }));
      }
    }
  };

  const handleEditorChange = (content: string, lang: "en" | "uk" | "fr") => {
    if (localClass) {
      setLocalClass((prevClass) => ({
        ...prevClass!,
        description: {
          ...prevClass!.description,
          [lang]: content,
        },
      }));

      if (lang === defaultLang && errors.description) {
        setErrors((prevErrors) => ({ ...prevErrors, description: undefined }));
      }
    }
  };

  const handleSelectChange =
    (field: "location" | "type") => (e: SelectChangeEvent) => {
      if (localClass) {
        const value = parseInt(e.target.value, 10);
        let updatedField;
        switch (field) {
          case "location":
            updatedField = locations.find((loc) => loc.id === value);
            break;
          case "type":
            updatedField = types.find((type) => type.id === value);
            break;
        }
        if (updatedField) {
          setLocalClass({
            ...localClass,
            [field]: updatedField,
          });
          if (errors[field]) {
            setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
          }
        }
      }
    };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (localClass) {
      setLocalClass({
        ...localClass,
        startDate: e.target.value,
      });
      if (errors.startDate) {
        setErrors((prevErrors) => ({ ...prevErrors, startDate: undefined }));
      }
    }
  };

  const handleClose = () => {
    onClose();
    setCurrentTab(process.env.REACT_APP_DEFAULT_LANGUAGE as "en" | "uk" | "fr");
    setDialogTab("class");
    setIsLoading(false);
    setLocalClass(null);
    setErrors({});
  };

  const validateForm = () => {
    const newErrors: {
      title?: string;
      description?: string;
      location?: string;
      type?: string;
      instructors?: string;
      maxAttendees?: string;
      startDate?: string;
    } = {};

    if (!localClass) {
      return newErrors;
    }

    if (!localClass.title[defaultLang].trim()) {
      newErrors.title = intl.formatMessage({ id: "validation.required" });
    }

    if (!localClass.description[defaultLang].trim()) {
      newErrors.description = intl.formatMessage({ id: "validation.required" });
    }

    if (!localClass.location || !localClass.location.id) {
      newErrors.location = intl.formatMessage({ id: "validation.required" });
    }

    if (!localClass.type || !localClass.type.id) {
      newErrors.type = intl.formatMessage({ id: "validation.required" });
    }

    if (!localClass.instructors || localClass.instructors.length === 0) {
      newErrors.instructors = intl.formatMessage({ id: "validation.required" });
    }

    if (
      localClass.maxAttendees === undefined ||
      localClass.maxAttendees === null ||
      localClass.maxAttendees <= 0
    ) {
      newErrors.maxAttendees = intl.formatMessage({
        id: "validation.invalidNumber",
      });
    }

    if (!localClass.startDate) {
      newErrors.startDate = intl.formatMessage({ id: "validation.required" });
    }

    return newErrors;
  };

  const handleSave = () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Notiflix.Notify.failure(
        intl.formatMessage({ id: "validation.formInvalid" })
      );
      return;
    }

    if (localClass) {
      const updatedClass: Omit<Class, "instructors"> & {
        instructors: Instructor[];
      } = {
        ...localClass,
        startDate: localClass.startDate ? new Date(localClass.startDate) : null,
      };
      onSave(updatedClass);
    }
    handleClose();
  };

  if (!localClass) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Tabs
        value={dialogTab}
        onChange={(_, newValue: "class" | "schedule" | "images") =>
          setDialogTab(newValue)
        }
        aria-label="class edit tabs"
      >
        <Tab
          label={intl.formatMessage({ id: "admin.dashboard.class" })}
          value="class"
        />
        <Tab
          label={intl.formatMessage({ id: "admin.dashboard.schedule" })}
          value="schedule"
        />
        <Tab
          label={intl.formatMessage({ id: "admin.dashboard.images" })}
          value="images"
        />
      </Tabs>
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          <FormattedMessage id="admin.dashboard.editClass" />
        </Typography>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : dialogTab === "class" ? (
          <>
            <Tabs
              value={currentTab}
              onChange={(_, newValue: "en" | "uk" | "fr") =>
                setCurrentTab(newValue)
              }
              aria-label="language tabs"
            >
              <Tab
                label={intl.formatMessage({
                  id:
                    (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" &&
                      "common.english") ||
                    (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" &&
                      "common.french") ||
                    "",
                })}
                value={process.env.REACT_APP_DEFAULT_LANGUAGE}
              />
              <Tab
                label={intl.formatMessage({ id: "common.ukrainian" })}
                value="uk"
                disabled={disDefaultLangEmpty}
              />
              <Tab
                label={intl.formatMessage({
                  id:
                    (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" &&
                      "common.french") ||
                    (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" &&
                      "common.english") ||
                    "",
                })}
                value={
                  (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" && "en") ||
                  (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" && "fr")
                }
                disabled={disDefaultLangEmpty}
              />
            </Tabs>
            <Box sx={{ mt: 2 }}>
              <TextField
                margin="dense"
                id={`title-${currentTab}`}
                label={intl.formatMessage({
                  id: "admin.dashboard.class.title",
                })}
                type="text"
                fullWidth
                value={localClass.title[currentTab]}
                onChange={(e) => handleTitleChange(e.target.value, currentTab)}
                error={!!errors.title && currentTab === defaultLang}
                helperText={currentTab === defaultLang ? errors.title : ""}
              />
              <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                {intl.formatMessage({ id: "admin.dashboard.description" })}
              </Typography>
              <Editor
                content={localClass.description[currentTab]}
                onChange={(content) => handleEditorChange(content, currentTab)}
                placeholder={intl.formatMessage({
                  id: "admin.dashboard.description.placeholder",
                })}
              />
              {currentTab === defaultLang && errors.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
            </Box>
            <FormControl
              fullWidth
              margin="dense"
              sx={{ mt: 2, mb: 2 }}
              error={!!errors.location}
            >
              <InputLabel id="location-select-label">
                <FormattedMessage id="admin.dashboard.location" />
              </InputLabel>
              <Select
                labelId="location-select-label"
                id="location-select"
                value={localClass.location.id.toString()}
                onChange={handleSelectChange("location")}
                label={intl.formatMessage({ id: "admin.dashboard.location" })}
              >
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id.toString()}>
                    {location.name[defaultLang] ||
                      location.name.fr ||
                      location.name.en ||
                      location.name.uk}
                  </MenuItem>
                ))}
              </Select>
              {errors.location && (
                <FormHelperText>{errors.location}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              margin="dense"
              sx={{ mb: 2 }}
              error={!!errors.type}
            >
              <InputLabel id="type-select-label">
                <FormattedMessage id="admin.dashboard.type" />
              </InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={localClass.type.id.toString()}
                onChange={handleSelectChange("type")}
                label={intl.formatMessage({ id: "admin.dashboard.type" })}
              >
                {types.map((type) => (
                  <MenuItem key={type.id} value={type.id.toString()}>
                    {type.name[defaultLang] ||
                      type.name.fr ||
                      type.name.en ||
                      type.name.uk}
                  </MenuItem>
                ))}
              </Select>
              {errors.type && <FormHelperText>{errors.type}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth margin="dense" error={!!errors.instructors}>
              <Autocomplete
                multiple
                id="instructors-autocomplete"
                options={instructors}
                getOptionLabel={(option) =>
                  `${option.firstname} ${option.lastname}`
                }
                value={localClass.instructors}
                onChange={(event, newValue) => {
                  setLocalClass({
                    ...localClass,
                    instructors: newValue,
                  });
                  if (errors.instructors) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      instructors: undefined,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: "admin.dashboard.instructor",
                    })}
                    error={!!errors.instructors}
                    helperText={errors.instructors}
                  />
                )}
              />
            </FormControl>
            <TextField
              margin="dense"
              id="maxAttendees"
              label={intl.formatMessage({
                id: "admin.dashboard.class.maxAttendees",
              })}
              type="number"
              fullWidth
              value={localClass.maxAttendees || ""}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                if (!isNaN(value)) {
                  setLocalClass({
                    ...localClass,
                    maxAttendees: value,
                  });
                } else {
                  setLocalClass({
                    ...localClass,
                    maxAttendees: 0,
                  });
                }
                if (errors.maxAttendees) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    maxAttendees: undefined,
                  }));
                }
              }}
              inputProps={{
                min: 1,
              }}
              error={!!errors.maxAttendees}
              helperText={errors.maxAttendees}
            />
            <TextField
              margin="dense"
              id="startDate"
              label={intl.formatMessage({
                id: "admin.dashboard.class.startDate",
              })}
              type="date"
              fullWidth
              value={localClass.startDate || ""}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.startDate}
              helperText={errors.startDate}
              disabled={classToEdit?.status === "Cancelled"}
              inputProps={{
                min: new Date().toISOString().split("T")[0],
              }}
            />
          </>
        ) : dialogTab === "schedule" ? (
          <ScheduleManager classId={localClass.id} />
        ) : dialogTab === "images" ? (
          <ClassImages classId={localClass.id} />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="admin.dashboard.cancel" />
        </Button>
        {dialogTab === "class" && (
          <Button onClick={handleSave} color="primary" disabled={isLoading}>
            <FormattedMessage id="admin.dashboard.save" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
