import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  fetchLocations,
  deleteLocation,
  updateLocation,
  createLocation,
} from "../../../redux/admin/adminOperations";
import { Location } from "../../../redux/types/types";
import Notiflix from "notiflix";
import { useIntl } from "react-intl";

type Order = "asc" | "desc";

export const useLocationManagement = () => {
  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { locations, totalLocations, loading, error } = useSelector(
    (state: RootState) => state.admin
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState<number | null>(null);
  const [locationToEdit, setLocationToEdit] = useState<Location | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState<keyof Location>("id");
  const [order, setOrder] = useState<Order>("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const fetchLocationData = useCallback(() => {
    dispatch(
      fetchLocations({
        page: page + 1,
        limit: rowsPerPage,
        sortBy: orderBy,
        sortOrder: order,
        search: searchTerm,
      })
    );
  }, [dispatch, page, rowsPerPage, orderBy, order, searchTerm]);

  const handleDeleteLocation = useCallback((locationId: number) => {
    setLocationToDelete(locationId);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDeleteLocation = useCallback(() => {
    if (locationToDelete) {
      dispatch(deleteLocation(locationToDelete))
        .unwrap()
        .then(() => {
          fetchLocationData();
          setDeleteDialogOpen(false);
          setLocationToDelete(null);
          setDeleteError(null);
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.deleteLocationSuccess" })
          );
        })
        .catch((error) => {
          let errorKey = "error.failedToDeleteLocation";
          if (error.message === "Location not found.") {
            errorKey = "error.locationNotFound";
          } else if (
            error.message === "Cannot delete location because it is in use."
          ) {
            errorKey = "error.locationInUse";
          } else if (error.message === "An unexpected error occurred.") {
            errorKey = "error.unexpectedError";
          }
          setDeleteError(errorKey);
        });
    }
  }, [locationToDelete, dispatch, fetchLocationData, intl]);

  const handleEditLocation = useCallback((location: Location) => {
    setLocationToEdit(location);
    setEditDialogOpen(true);
  }, []);

  const handleUpdateLocation = useCallback(
    (location: Location) => {
      if (location && location.id) {
        dispatch(
          updateLocation({
            locationId: location.id,
            locationData: location,
          })
        )
          .unwrap()
          .then(() => {
            fetchLocationData();
            setEditDialogOpen(false);
            setLocationToEdit(null);
            Notiflix.Notify.success(
              intl.formatMessage({ id: "admin.editLocationSuccess" })
            );
          })
          .catch((error) => {
            Notiflix.Notify.failure(
              intl.formatMessage({ id: "admin.editLocationFailure" })
            );
            console.error("Failed to update location:", error);
          });
      }
    },
    [dispatch, fetchLocationData, intl]
  );

  const onLocationUpdate = async (location: Location) => {
    await setLocationToEdit(location);
    await handleUpdateLocation(location);
  };

  const handleCreateLocation = useCallback(
    (locationData: Omit<Location, "id">) => {
      dispatch(createLocation(locationData))
        .unwrap()
        .then(() => {
          fetchLocationData();
          setCreateDialogOpen(false);
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.cereateLocationSuccess" })
          );
        })
        .catch((error) => {
          Notiflix.Notify.failure(
            intl.formatMessage({ id: "admin.cereateLocationFailure" })
          );
          console.error("Failed to create location:", error);
        });
    },
    [dispatch, fetchLocationData, intl]
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const handleRequestSort = useCallback(
    (property: keyof Location) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(0);
    },
    []
  );

  return {
    locations,
    totalLocations,
    loading,
    error,
    deleteDialogOpen,
    editDialogOpen,
    createDialogOpen,
    locationToDelete,
    locationToEdit,
    page,
    rowsPerPage,
    orderBy,
    order,
    searchTerm,
    deleteError,
    fetchLocationData,
    handleDeleteLocation,
    confirmDeleteLocation,
    handleEditLocation,
    handleUpdateLocation,
    handleCreateLocation,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSearchChange,
    setDeleteDialogOpen,
    setEditDialogOpen,
    setCreateDialogOpen,
    setLocationToEdit,
    setDeleteError,
    onLocationUpdate,
  };
};
