import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { Attendee } from "../../../../../redux/types/types";

interface AttendeeDialogProps {
  open: boolean;
  onClose: () => void;
  attendee: Attendee | null;
}

const AttendeeDialog: React.FC<AttendeeDialogProps> = ({
  open,
  onClose,
  attendee,
}) => {
  const intl = useIntl();

  if (!attendee) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage
          id="admin.dashboard.attendeeDetails"
          defaultMessage="Attendee Details"
        />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage
            id="admin.dashboard.attendeeName"
            defaultMessage="Name:"
          />{" "}
          <strong>{`${attendee.firstName} ${attendee.lastName}`}</strong>
        </Typography>
        <Typography>
          <FormattedMessage
            id="admin.dashboard.attendeeBirthdate"
            defaultMessage="Birthdate:"
          />{" "}
          <strong>{intl.formatDate(new Date(attendee.birthdate))}</strong>
        </Typography>
        <Typography>
          <FormattedMessage
            id="admin.dashboard.attendeeSex"
            defaultMessage="Sex:"
          />{" "}
          <strong>{attendee.sex}</strong>
        </Typography>
        <Typography>
          <FormattedMessage
            id="admin.dashboard.attendeeEmail"
            defaultMessage="Email:"
          />{" "}
          <strong>{attendee.email}</strong>
        </Typography>
        <Typography>
          <FormattedMessage id="signUp.phone" defaultMessage="Phone:" />
          {": "}
          <strong>{attendee.phone}</strong>
        </Typography>
        <Typography>
          <FormattedMessage
            id="signUp.postalCode"
            defaultMessage="Postal code:"
          />
          {": "}
          <strong>{attendee.postalCode}</strong>
        </Typography>
        <Typography>
          <FormattedMessage id="attendee.permisStatus.title" />
          {": "}
          <strong>
            {(attendee.permisStatus === "PermisS" &&
              intl.formatMessage({
                id: "attendee.permisStatus.permisS",
              })) ||
              (attendee.permisStatus === "Other" &&
                intl.formatMessage({
                  id: "attendee.permisStatus.other",
                }))}
          </strong>
        </Typography>
        {attendee.user && (
          <>
            <Typography>
              <FormattedMessage
                id="admin.dashboard.attendeeUserName"
                defaultMessage="User Name:"
              />{" "}
              <strong>{`${attendee.user.firstname} ${attendee.user.lastname}`}</strong>
            </Typography>
            <Typography>
              <FormattedMessage
                id="admin.dashboard.attendeeUserEmail"
                defaultMessage="User Email:"
              />{" "}
              <strong>{attendee.user.email}</strong>
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="admin.dashboard.close" defaultMessage="Close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttendeeDialog;
