import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  SelectChangeEvent,
  Tooltip,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  fetchLocations,
  fetchTypes,
  fetchInstructors,
  createClass,
} from "../../../../redux/admin/adminOperations";
import ScheduleManager from "../ScheduleManager/ScheduleManager";
import Editor from "../../../../utils/styles/StyledEditor";
import { Class, Instructor } from "../../../../redux/types/types";
import ClassImages from "../ClassImages/ClassImages";
import Notiflix from "notiflix";
import { Language } from "../../../LanguageProvider/LanguageProvider";

interface CreateClassDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (
    classData: Omit<Class, "id" | "instructors"> & {
      instructors: Instructor[];
    }
  ) => void;
  onOpenEditDialog: (classId: number) => void;
  setPage: (page: number) => void;
  rowsPerPage: number;
}

type LanguageFields = {
  en: string;
  uk: string;
  fr: string;
};

type NewClass = {
  title: LanguageFields;
  description: LanguageFields;
  locationId: string;
  typeId: string;
  instructorIds: string[];
  maxAttendees: number;
  startDate: string;
};

export default function CreateClassDialog({
  open,
  onClose,
  onSave,
  onOpenEditDialog,
  setPage,
  rowsPerPage,
}: CreateClassDialogProps) {
  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const [currentTab, setCurrentTab] = useState<"en" | "uk" | "fr">(
    process.env.REACT_APP_DEFAULT_LANGUAGE as "en" | "uk" | "fr"
  );
  const [dialogTab, setDialogTab] = useState<"class" | "schedule" | "images">(
    "class"
  );
  const locations = useSelector((state: RootState) => state.admin.locations);
  const types = useSelector((state: RootState) => state.admin.types);
  const instructors = useSelector(
    (state: RootState) => state.admin.instructors
  );
  const [isLoading, setIsLoading] = useState(false);

  const [newClass, setNewClass] = useState<NewClass>({
    title: { en: "", uk: "", fr: "" },
    description: { en: "", uk: "", fr: "" },
    locationId: "",
    typeId: "",
    instructorIds: [],
    maxAttendees: 0,
    startDate: "",
  });

  const [isDefaultLangEmpty, setIsDefaultLangEmpty] = useState(true);
  const [createdClassId, setCreatedClassId] = useState<number | null>(null);

  const [errors, setErrors] = useState<{
    title?: string;
    description?: string;
    locationId?: string;
    typeId?: string;
    instructorIds?: string;
    maxAttendees?: string;
    startDate?: string;
  }>({});

  const defaultLang = process.env.REACT_APP_DEFAULT_LANGUAGE as Language;

  useEffect(() => {
    setIsDefaultLangEmpty(
      newClass.title[defaultLang].trim() === "" ||
        newClass.description[defaultLang].trim() === ""
    );
  }, [newClass.title, newClass.description, defaultLang]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      Promise.all([
        dispatch(
          fetchLocations({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
        dispatch(
          fetchTypes({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
        dispatch(
          fetchInstructors({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
      ]).then(() => {
        setIsLoading(false);
      });
    }
  }, [open, dispatch]);

  const handleTitleChange = (value: string, lang: "en" | "uk" | "fr") => {
    setNewClass((prevClass) => ({
      ...prevClass,
      title: {
        ...prevClass.title,
        [lang]: value,
      },
    }));

    if (lang === defaultLang && errors.title) {
      setErrors((prevErrors) => ({ ...prevErrors, title: undefined }));
    }
  };

  const handleEditorChange = (content: string, lang: "en" | "uk" | "fr") => {
    setNewClass((prevClass) => ({
      ...prevClass,
      description: {
        ...prevClass.description,
        [lang]: content,
      },
    }));

    if (lang === defaultLang && errors.description) {
      setErrors((prevErrors) => ({ ...prevErrors, description: undefined }));
    }
  };

  const handleSelectChange =
    (field: "locationId" | "typeId") => (e: SelectChangeEvent) => {
      const value = e.target.value;
      setNewClass({
        ...newClass,
        [field]: value,
      });

      if (errors[field]) {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
      }
    };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewClass({
      ...newClass,
      startDate: e.target.value,
    });

    if (errors.startDate) {
      setErrors((prevErrors) => ({ ...prevErrors, startDate: undefined }));
    }
  };

  const validateForm = () => {
    const newErrors: {
      title?: string;
      description?: string;
      locationId?: string;
      typeId?: string;
      instructorIds?: string;
      maxAttendees?: string;
      startDate?: string;
    } = {};

    if (!newClass.title[defaultLang].trim()) {
      newErrors.title = intl.formatMessage({ id: "validation.required" });
    }

    if (!newClass.description[defaultLang].trim()) {
      newErrors.description = intl.formatMessage({ id: "validation.required" });
    }

    if (!newClass.locationId) {
      newErrors.locationId = intl.formatMessage({ id: "validation.required" });
    }

    if (!newClass.typeId) {
      newErrors.typeId = intl.formatMessage({ id: "validation.required" });
    }

    if (!newClass.instructorIds || newClass.instructorIds.length === 0) {
      newErrors.instructorIds = intl.formatMessage({
        id: "validation.required",
      });
    }

    if (!newClass.maxAttendees || newClass.maxAttendees <= 0) {
      newErrors.maxAttendees = intl.formatMessage({
        id: "validation.invalidNumber",
      });
    }

    if (!newClass.startDate) {
      newErrors.startDate = intl.formatMessage({ id: "validation.required" });
    }

    return newErrors;
  };

  const handleSave = () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Notiflix.Notify.failure(
        intl.formatMessage({ id: "validation.formInvalid" })
      );
      return;
    }

    const selectedLocation = locations.find(
      (loc) => loc.id === parseInt(newClass.locationId, 10)
    );
    const selectedType = types.find(
      (type) => type.id === parseInt(newClass.typeId, 10)
    );
    const selectedInstructors = instructors.filter((inst) =>
      newClass.instructorIds.includes(inst.id.toString())
    );

    if (selectedLocation && selectedType && selectedInstructors.length > 0) {
      const classData: Omit<Class, "id" | "instructors"> & {
        instructors: Instructor[];
      } = {
        title: newClass.title,
        description: newClass.description,
        location: selectedLocation,
        type: selectedType,
        instructors: selectedInstructors,
        schedules: [],
        maxAttendees: newClass.maxAttendees,
        startDate: new Date(newClass.startDate),
      };
      onSave(classData);
    }

    handleClose();
  };

  const handleOpenSchedule = async () => {
    if (createdClassId) {
      setDialogTab("schedule");
    } else {
      const validationErrors = validateForm();

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        Notiflix.Notify.failure(
          intl.formatMessage({ id: "validation.formInvalid" })
        );
        return;
      }

      const selectedLocation = locations.find(
        (loc) => loc.id === parseInt(newClass.locationId, 10)
      );
      const selectedType = types.find(
        (type) => type.id === parseInt(newClass.typeId, 10)
      );
      const selectedInstructors = instructors.filter((inst) =>
        newClass.instructorIds.includes(inst.id.toString())
      );

      if (selectedLocation && selectedType && selectedInstructors.length > 0) {
        const classData: Omit<Class, "id" | "instructors"> & {
          instructors: Instructor[];
        } = {
          title: newClass.title,
          description: newClass.description,
          location: selectedLocation,
          type: selectedType,
          instructors: selectedInstructors,
          schedules: [],
          maxAttendees: newClass.maxAttendees,
          startDate: new Date(newClass.startDate),
        };

        dispatch(createClass(classData))
          .unwrap()
          .then(async (data) => {
            Notiflix.Notify.success(
              intl.formatMessage({ id: "admin.dashboard.createClassSuccess" })
            );
            const page = Math.floor(data.total / rowsPerPage);
            await setPage(page);
            setCreatedClassId(data.id);
            setDialogTab("schedule");
          })
          .catch((error) => {
            Notiflix.Notify.failure(
              intl.formatMessage({ id: "admin.dashboard.createClassError" })
            );
            console.error("Failed to create class:", error);
          });
      }
    }
  };

  const handleOpenImages = async () => {
    if (createdClassId) {
      setDialogTab("images");
    } else {
      const validationErrors = validateForm();

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        Notiflix.Notify.failure(
          intl.formatMessage({ id: "validation.formInvalid" })
        );
        return;
      }

      const selectedLocation = locations.find(
        (loc) => loc.id === parseInt(newClass.locationId, 10)
      );
      const selectedType = types.find(
        (type) => type.id === parseInt(newClass.typeId, 10)
      );
      const selectedInstructors = instructors.filter((inst) =>
        newClass.instructorIds.includes(inst.id.toString())
      );

      if (selectedLocation && selectedType && selectedInstructors.length > 0) {
        const classData: Omit<Class, "id" | "instructors"> & {
          instructors: Instructor[];
        } = {
          title: newClass.title,
          description: newClass.description,
          location: selectedLocation,
          type: selectedType,
          instructors: selectedInstructors,
          schedules: [],
          maxAttendees: newClass.maxAttendees,
          startDate: new Date(newClass.startDate),
        };

        dispatch(createClass(classData))
          .unwrap()
          .then(async (data) => {
            Notiflix.Notify.success(
              intl.formatMessage({ id: "admin.dashboard.createClassSuccess" })
            );
            const page = Math.floor(data.total / rowsPerPage);
            await setPage(page);
            setCreatedClassId(data.id);
            setDialogTab("images");
          })
          .catch((error) => {
            Notiflix.Notify.failure(
              intl.formatMessage({ id: "admin.dashboard.createClassError" })
            );
            console.error("Failed to create class:", error);
          });
      }
    }
  };

  const handleClose = () => {
    onClose();
    setCurrentTab(process.env.REACT_APP_DEFAULT_LANGUAGE as "en" | "uk" | "fr");
    setDialogTab("class");
    setCreatedClassId(null);
    setNewClass({
      title: { en: "", uk: "", fr: "" },
      description: { en: "", uk: "", fr: "" },
      locationId: "",
      typeId: "",
      instructorIds: [],
      maxAttendees: 0,
      startDate: "",
    });
    setErrors({});
    setIsDefaultLangEmpty(true);
    setIsLoading(false);
  };

  const handleOpenClass = async () => {
    if (createdClassId) {
      await onOpenEditDialog(createdClassId);
      await handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Tabs value={dialogTab} aria-label="class creation tabs">
        <Tab
          label={intl.formatMessage({ id: "admin.dashboard.class" })}
          value="class"
          onClick={handleOpenClass}
        />
        <Tab
          label={
            isDefaultLangEmpty ? (
              <Tooltip
                title={intl.formatMessage({
                  id: "admin.dashboard.classInputsFilled",
                })}
              >
                <span>
                  {intl.formatMessage({ id: "admin.dashboard.schedule" })}
                </span>
              </Tooltip>
            ) : (
              intl.formatMessage({ id: "admin.dashboard.schedule" })
            )
          }
          value="schedule"
          disabled={isDefaultLangEmpty}
          onClick={handleOpenSchedule}
          sx={
            isDefaultLangEmpty
              ? {
                  pointerEvents: "auto",
                  "&.Mui-disabled": {
                    pointerEvents: "auto",
                  },
                  "& .MuiTab-wrapper": {
                    pointerEvents: "none",
                  },
                }
              : undefined
          }
        />
        <Tab
          label={
            isDefaultLangEmpty ? (
              <Tooltip
                title={intl.formatMessage({
                  id: "admin.dashboard.classInputsFilled",
                })}
              >
                <span>
                  {intl.formatMessage({ id: "admin.dashboard.images" })}
                </span>
              </Tooltip>
            ) : (
              intl.formatMessage({ id: "admin.dashboard.images" })
            )
          }
          value="images"
          disabled={isDefaultLangEmpty}
          onClick={handleOpenImages}
          sx={
            isDefaultLangEmpty
              ? {
                  pointerEvents: "auto",
                  "&.Mui-disabled": {
                    pointerEvents: "auto",
                  },
                  "& .MuiTab-wrapper": {
                    pointerEvents: "none",
                  },
                }
              : undefined
          }
        />
      </Tabs>
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          <FormattedMessage id="admin.dashboard.createClass" />
        </Typography>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : dialogTab === "class" ? (
          <>
            <Tabs
              value={currentTab}
              onChange={(_, newValue: "en" | "uk" | "fr") =>
                setCurrentTab(newValue)
              }
              aria-label="language tabs"
            >
              <Tab
                label={intl.formatMessage({
                  id:
                    (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" &&
                      "common.english") ||
                    (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" &&
                      "common.french") ||
                    "",
                })}
                value={process.env.REACT_APP_DEFAULT_LANGUAGE}
              />
              <Tab
                label={
                  isDefaultLangEmpty ? (
                    <Tooltip
                      title={intl.formatMessage(
                        {
                          id: "admin.dashboard.fillDefaultFirst",
                        },
                        {
                          default:
                            (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" &&
                              intl.formatMessage({ id: "common.english" })) ||
                            (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" &&
                              intl.formatMessage({ id: "common.french" })) ||
                            "",
                        }
                      )}
                    >
                      <span>
                        {intl.formatMessage({ id: "common.ukrainian" })}
                      </span>
                    </Tooltip>
                  ) : (
                    intl.formatMessage({ id: "common.ukrainian" })
                  )
                }
                value="uk"
                disabled={isDefaultLangEmpty}
                sx={
                  isDefaultLangEmpty
                    ? {
                        pointerEvents: "auto",
                        "&.Mui-disabled": {
                          pointerEvents: "auto",
                        },
                        "& .MuiTab-wrapper": {
                          pointerEvents: "none",
                        },
                      }
                    : undefined
                }
              />
              <Tab
                label={
                  isDefaultLangEmpty ? (
                    <Tooltip
                      title={intl.formatMessage(
                        {
                          id: "admin.dashboard.fillDefaultFirst",
                        },
                        {
                          default:
                            (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" &&
                              intl.formatMessage({ id: "common.english" })) ||
                            (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" &&
                              intl.formatMessage({ id: "common.french" })) ||
                            "",
                        }
                      )}
                    >
                      <span>
                        {intl.formatMessage({
                          id:
                            (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" &&
                              "common.french") ||
                            (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" &&
                              "common.english") ||
                            "",
                        })}
                      </span>
                    </Tooltip>
                  ) : (
                    intl.formatMessage({
                      id:
                        (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" &&
                          "common.french") ||
                        (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" &&
                          "common.english") ||
                        "",
                    })
                  )
                }
                value={
                  (process.env.REACT_APP_DEFAULT_LANGUAGE === "fr" && "en") ||
                  (process.env.REACT_APP_DEFAULT_LANGUAGE === "en" && "fr")
                }
                disabled={isDefaultLangEmpty}
                sx={
                  isDefaultLangEmpty
                    ? {
                        pointerEvents: "auto",
                        "&.Mui-disabled": {
                          pointerEvents: "auto",
                        },
                        "& .MuiTab-wrapper": {
                          pointerEvents: "none",
                        },
                      }
                    : undefined
                }
              />
            </Tabs>
            <Box sx={{ mt: 2 }}>
              <TextField
                margin="dense"
                id={`title-${currentTab}`}
                label={intl.formatMessage({
                  id: "admin.dashboard.class.title",
                })}
                type="text"
                fullWidth
                value={newClass.title[currentTab]}
                onChange={(e) => handleTitleChange(e.target.value, currentTab)}
                error={!!errors.title && currentTab === defaultLang}
                helperText={currentTab === defaultLang ? errors.title : ""}
              />
              <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                {intl.formatMessage({ id: "admin.dashboard.description" })}
              </Typography>
              <Editor
                content={newClass.description[currentTab]}
                onChange={(content) => handleEditorChange(content, currentTab)}
                placeholder={intl.formatMessage({
                  id: "admin.dashboard.description.placeholder",
                })}
              />
              {currentTab === defaultLang && errors.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
            </Box>
            <FormControl
              fullWidth
              margin="dense"
              sx={{ mt: 2, mb: 2 }}
              error={!!errors.locationId}
            >
              <InputLabel id="location-select-label">
                <FormattedMessage id="admin.dashboard.location" />
              </InputLabel>
              <Select
                labelId="location-select-label"
                id="location-select"
                value={newClass.locationId}
                onChange={handleSelectChange("locationId")}
                label={intl.formatMessage({ id: "admin.dashboard.location" })}
              >
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id.toString()}>
                    {location.name[defaultLang] ||
                      location.name.fr ||
                      location.name.en ||
                      location.name.uk}
                  </MenuItem>
                ))}
              </Select>
              {errors.locationId && (
                <FormHelperText>{errors.locationId}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              margin="dense"
              sx={{ mb: 2 }}
              error={!!errors.typeId}
            >
              <InputLabel id="type-select-label">
                <FormattedMessage id="admin.dashboard.type" />
              </InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={newClass.typeId}
                onChange={handleSelectChange("typeId")}
                label={intl.formatMessage({ id: "admin.dashboard.type" })}
              >
                {types.map((type) => (
                  <MenuItem key={type.id} value={type.id.toString()}>
                    {type.name[defaultLang] ||
                      type.name.fr ||
                      type.name.en ||
                      type.name.uk}
                  </MenuItem>
                ))}
              </Select>
              {errors.typeId && (
                <FormHelperText>{errors.typeId}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              margin="dense"
              error={!!errors.instructorIds}
            >
              <Autocomplete
                multiple
                id="instructors-autocomplete"
                options={instructors}
                getOptionLabel={(option) =>
                  `${option.firstname} ${option.lastname}`
                }
                value={instructors.filter((inst) =>
                  newClass.instructorIds.includes(inst.id.toString())
                )}
                onChange={(event, newValue) => {
                  const selectedIds = newValue.map((inst) =>
                    inst.id.toString()
                  );
                  setNewClass({
                    ...newClass,
                    instructorIds: selectedIds,
                  });
                  if (errors.instructorIds) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      instructorIds: undefined,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: "admin.dashboard.instructor",
                    })}
                    error={!!errors.instructorIds}
                    helperText={errors.instructorIds}
                  />
                )}
              />
            </FormControl>
            <TextField
              margin="dense"
              id="maxAttendees"
              label={intl.formatMessage({
                id: "admin.dashboard.class.maxAttendees",
              })}
              type="number"
              fullWidth
              value={newClass.maxAttendees || ""}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                if (!isNaN(value)) {
                  setNewClass({
                    ...newClass,
                    maxAttendees: value,
                  });
                } else {
                  setNewClass({
                    ...newClass,
                    maxAttendees: 0,
                  });
                }
                if (errors.maxAttendees) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    maxAttendees: undefined,
                  }));
                }
              }}
              required
              inputProps={{
                min: 1,
              }}
              error={!!errors.maxAttendees}
              helperText={errors.maxAttendees}
            />
            <TextField
              margin="dense"
              id="startDate"
              label={intl.formatMessage({
                id: "admin.dashboard.class.startDate",
              })}
              type="date"
              fullWidth
              value={newClass.startDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              error={!!errors.startDate}
              helperText={errors.startDate}
              inputProps={{
                min: new Date().toISOString().split("T")[0],
              }}
            />
          </>
        ) : dialogTab === "schedule" && createdClassId ? (
          <ScheduleManager classId={createdClassId} />
        ) : dialogTab === "images" && createdClassId ? (
          <ClassImages classId={createdClassId} />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="admin.dashboard.cancel" />
        </Button>
        {!createdClassId && (
          <Button onClick={handleSave} color="primary" disabled={isLoading}>
            <FormattedMessage id="admin.dashboard.create" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
