import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Alert,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

interface DeleteTypeDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  error: string | null;
}

export default function DeleteTypeDialog({
  open,
  onClose,
  onConfirm,
  error,
}: DeleteTypeDialogProps) {
  const intl = useIntl();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {intl.formatMessage({ id: error })}
        </Alert>
      )}
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id="admin.dashboard.deleteTypeConfirmTitle" />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage id="admin.dashboard.deleteTypeConfirmMessage" />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="admin.dashboard.cancel" />
        </Button>
        <Button onClick={onConfirm} autoFocus>
          <FormattedMessage id="admin.dashboard.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
