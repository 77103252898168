import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import {
  fetchAttendeesForUser,
  deleteAttendee,
} from "../../../../../redux/admin/adminOperations";
import { Attendee } from "../../../../../redux/types/types";
import Notiflix from "notiflix";
import EditAttendeeForm from "./EditAttendeeForm/EditAttendeeForm";
import CreateAttendeeForm from "./CreateAttendeeForm/CreateAttendeeForm";

interface AttendeesListProps {
  userId: number;
}

const calculateAge = (birthdate: Date) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export default function AttendeesList({ userId }: AttendeesListProps) {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const attendees = useSelector((state: RootState) => state.admin.attendees);
  const user = useSelector((state: RootState) =>
    state.admin.users.find((u) => u.id === userId)
  );
  const [editAttendee, setEditAttendee] = useState<Attendee | null>(null);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchAttendeesForUser(userId));
  }, [dispatch, userId]);

  const handleEditClick = (attendee: Attendee) => {
    setEditAttendee(attendee);
  };

  const handleDeleteClick = (attendeeId: number) => {
    Notiflix.Confirm.show(
      intl.formatMessage({ id: "user.attendee.deleteConfTitle" }),
      intl.formatMessage({ id: "user.attendee.deleteConfMessage" }),
      intl.formatMessage({ id: "common.delete" }),
      intl.formatMessage({ id: "common.cancel" }),
      () => {
        dispatch(deleteAttendee(attendeeId))
          .unwrap()
          .then(() => {
            dispatch(fetchAttendeesForUser(userId));
          })
          .catch((error) => {
            console.error("Failed to delete attendee:", error);
          });
      }
    );
  };

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsCreateFormOpen(true)}
        sx={{ mb: 2 }}
      >
        <FormattedMessage
          id="attendees.createNew"
          defaultMessage="Create New Attendee"
        />
      </Button>
      <Grid container spacing={2}>
        {attendees?.map((attendee) => (
          <Grid item xs={12} sm={6} key={attendee.id}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  {`${attendee.firstName} ${attendee.lastName}`}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="admin.dashboard.email" />:{" "}
                  {attendee.email}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="admin.dashboard.phone" />:{" "}
                  {attendee.phone}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="admin.dashboard.birthdate" />:{" "}
                  {intl.formatDate(attendee.birthdate, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="admin.dashboard.age" />:{" "}
                  {calculateAge(attendee.birthdate)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="admin.dashboard.sex" />:{" "}
                  {attendee.sex === "female"
                    ? intl.formatMessage({
                        id: "privateEnroll.female",
                      })
                    : attendee.sex === "male"
                    ? intl.formatMessage({
                        id: "privateEnroll.male",
                      })
                    : attendee.sex === "other"
                    ? intl.formatMessage({
                        id: "privateEnroll.other",
                      })
                    : attendee.sex}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="signUp.postalCode" />:{" "}
                  {attendee.postalCode || "N/A"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="attendee.permisStatus.title" />:{" "}
                  {(attendee.permisStatus === "PermisS" &&
                    intl.formatMessage({
                      id: "attendee.permisStatus.permisS",
                    })) ||
                    (attendee.permisStatus === "Other" &&
                      intl.formatMessage({
                        id: "attendee.permisStatus.other",
                      }))}
                </Typography>
              </CardContent>
              <Box sx={{ p: 2, mt: "auto" }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleEditClick(attendee)}
                  sx={{ mr: 1 }}
                >
                  <FormattedMessage id="attendees.edit" defaultMessage="Edit" />
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => handleDeleteClick(attendee.id)}
                >
                  <FormattedMessage
                    id="attendees.delete"
                    defaultMessage="Delete"
                  />
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      {editAttendee && (
        <EditAttendeeForm
          open={!!editAttendee}
          onClose={() => setEditAttendee(null)}
          attendee={editAttendee}
        />
      )}
      {user && (
        <CreateAttendeeForm
          open={isCreateFormOpen}
          onClose={() => setIsCreateFormOpen(false)}
          user={user}
        />
      )}
    </Box>
  );
}
