import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  fetchClasses,
  deleteClass,
  updateClass,
  createClass,
  setClassInProgressOfCancellation,
  reactivateClass,
} from "../../../redux/admin/adminOperations";
import { Class, Instructor } from "../../../redux/types/types";
import Notiflix from "notiflix";
import { useIntl } from "react-intl";

type Order = "asc" | "desc";

export const useClassManagement = () => {
  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { classes, totalClasses, loading, error } = useSelector(
    (state: RootState) => state.admin
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [classToDelete, setClassToDelete] = useState<number | null>(null);
  const [classToEdit, setClassToEdit] = useState<Class | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState<keyof Class>("id");
  const [order, setOrder] = useState<Order>("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const fetchClassData = useCallback(() => {
    dispatch(
      fetchClasses({
        page: page + 1,
        limit: rowsPerPage,
        sortBy: orderBy,
        sortOrder: order,
        search: searchTerm,
      })
    );
  }, [dispatch, page, rowsPerPage, orderBy, order, searchTerm]);

  const handleDeleteClass = useCallback((classId: number) => {
    setClassToDelete(classId);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDeleteClass = useCallback(() => {
    if (classToDelete) {
      dispatch(deleteClass(classToDelete))
        .unwrap()
        .then(() => {
          fetchClassData();
          setDeleteDialogOpen(false);
          setClassToDelete(null);
          setDeleteError(null);
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.deleteClassSuccess" })
          );
        })
        .catch((error) => {
          let errorKey = "error.failedToDeleteClass";
          if (error.message === "Class not found.") {
            errorKey = "error.classNotFound";
          } else if (
            error.message ===
            "Cannot delete class because it is associated with other records."
          ) {
            errorKey = "error.classInUse";
          } else if (error.message === "An unexpected error occurred.") {
            errorKey = "error.unexpectedError";
          }
          setDeleteError(errorKey);
        });
    }
  }, [classToDelete, dispatch, fetchClassData, intl]);

  const handleEditClass = useCallback((classItem: Class) => {
    setClassToEdit(classItem);
    setEditDialogOpen(true);
  }, []);

  const handleUpdateClass = useCallback(
    (
      updatedClass: Omit<Class, "instructors"> & {
        instructors: Instructor[];
      }
    ) => {
      if (updatedClass && updatedClass.id) {
        dispatch(
          updateClass({
            classId: updatedClass.id,
            classData: updatedClass,
          })
        )
          .unwrap()
          .then(() => {
            Notiflix.Notify.success(
              intl.formatMessage({ id: "admin.dashboard.updateClassSuccess" })
            );
            fetchClassData();
            setEditDialogOpen(false);
            setClassToEdit(null);
          })
          .catch((error) => {
            Notiflix.Notify.failure(
              intl.formatMessage({ id: "admin.dashboard.updateClassError" })
            );
            console.error("Failed to update class:", error);
          });
      }
    },
    // eslint-disable-next-line
    [dispatch, fetchClassData]
  );

  const handleCreateClass = useCallback(
    async (
      classData: Omit<Class, "id" | "instructors"> & {
        instructors: Instructor[];
      }
    ) => {
      await dispatch(createClass(classData))
        .unwrap()
        .then(async (data) => {
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.dashboard.createClassSuccess" })
          );
          const page = Math.floor(data.total / rowsPerPage);
          await setPage(page);
          await setCreateDialogOpen(false);
        })
        .catch((error) => {
          Notiflix.Notify.failure(
            intl.formatMessage({ id: "admin.dashboard.createClassError" })
          );
          console.error("Failed to create class:", error);
        });
    },
    // eslint-disable-next-line
    [dispatch, fetchClassData]
  );

  const handleSetClassInProgressOfCancellation = useCallback(
    (classId: number, cancelDate: string) => {
      dispatch(
        setClassInProgressOfCancellation({
          classId,
          cancelDate,
          language: intl.locale,
        })
      )
        .unwrap()
        .then(() => {
          fetchClassData();
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.classInCancellationSuccess" })
          );
        })
        .catch((error) => {
          Notiflix.Notify.failure(
            intl.formatMessage({ id: "admin.classInCancellationFailure" })
          );
          console.error(
            "Failed to set class in progress of cancellation:",
            error
          );
        });
    },
    [dispatch, fetchClassData, intl]
  );

  const handleReactivateClass = useCallback(
    (classId: number) => {
      dispatch(reactivateClass(classId))
        .unwrap()
        .then(() => {
          fetchClassData();
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.reactivateClassSuccess" })
          );
        })
        .catch((error) => {
          Notiflix.Notify.failure(
            intl.formatMessage({ id: "admin.reactivateClassFailure" })
          );
          console.error("Failed to reactivate class:", error);
        });
    },
    [dispatch, fetchClassData, intl]
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const handleRequestSort = useCallback(
    (property: keyof Class) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(0);
    },
    []
  );

  return {
    classes,
    totalClasses,
    loading,
    error,
    deleteDialogOpen,
    editDialogOpen,
    createDialogOpen,
    classToDelete,
    classToEdit,
    page,
    rowsPerPage,
    orderBy,
    order,
    searchTerm,
    deleteError,
    fetchClassData,
    handleDeleteClass,
    confirmDeleteClass,
    handleEditClass,
    handleUpdateClass,
    handleCreateClass,
    handleSetClassInProgressOfCancellation,
    handleReactivateClass,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSearchChange,
    setDeleteDialogOpen,
    setEditDialogOpen,
    setCreateDialogOpen,
    setClassToEdit,
    setDeleteError,
    setPage,
  };
};
