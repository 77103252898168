import api from "./axiosInstance";

export const fetchAttendeesApi = (params: {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}) =>
  api.get("/attendee", {
    params: params,
  });

export const fetchAttendeesForUserApi = (userId: number) => {
  return api.get(`/attendee/user/${userId}`);
};

export const createAttendeeApi = (attendeeData: any) =>
  api.post("/attendee", attendeeData);

export const deleteAttendeeApi = (attendeeId: number) =>
  api.delete(`/attendee/${attendeeId}`);

export const getAttendeeApi = (attendeeId: number) =>
  api.get(`/attendee/${attendeeId}`);

export const updateAttendeeApi = (attendeeId: number, attendeeData: any) =>
  api.put(`/attendee/${attendeeId}`, attendeeData);

export const createAttendeeToClassApi = (enrollmentData: {
  attendeeId: number;
  classId: number;
}) => api.post("/attendee/class", enrollmentData);

export const fetchAttendeesByUserIdApi = (userId: number) =>
  api.get(`/attendee/user/${userId}`);

export const fetchClassPublicApi = async (classId: number) =>
  await api.get(`/public/classes/${classId}`);

export const createAttendeeToScheduleApi = (data: {
  attendeeId: number;
  scheduleId: number;
}) => api.post("/attendee/schedule", data);

export const createAttendeeToSchedulesApi = (
  data: {
    attendeeId: number;
    scheduleIds: number[];
  },
  language: string
) => api.post(`/attendee/schedules?language=${language}`, data);

export const fetchAttendeeToSchedulesApi = () => api.get("/attendee/schedule");

export const getAttendeeToScheduleApi = (id: number) =>
  api.get(`/attendee/schedule/${id}`);

export const getAttendeesToScheduleApi = (scheduleId: number) =>
  api.get(`/attendee/schedule/attendees/${scheduleId}`);

export const updateAttendeeToScheduleApi = (
  id: number,
  data: { attendeeId?: number; scheduleId?: number }
) => api.put(`/attendee/schedule/${id}`, data);

export const deleteAttendeeToScheduleApi = (id: number) =>
  api.delete(`/attendee/schedule/${id}`);

export const createAttendeeAbsenceApi = (data: {
  scheduleId: number;
  date: Date;
  attendeeIds: number[];
}) => api.post("/instructor/absences", data);

export const deleteAttendeeAbsenceApi = (data: {
  scheduleId: number;
  date: Date;
  attendeeIds: number[];
}) => api.delete("/instructor/absences", { data });

export const enrollWaitingAttendeeApi = (
  waitingAttendeeId: number,
  language: string
) =>
  api.post(
    `/attendee/waiting-attendee/${waitingAttendeeId}/enroll?language=${language}`
  );

export const removeAttendeeSchedulesForClassApi = (
  attendeeId: number,
  classId: number
) => api.delete(`/attendee/${attendeeId}/class/${classId}/schedules`);

export const unenrollAttendeeFromSchedulesApi = (data: {
  attendeeId: number;
  scheduleIds: number[];
}) => api.patch("/attendee/unenroll", data);
