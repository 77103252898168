import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createAttendee,
  createAttendeeAbsences,
  createAttendeeAdm,
  createAttendeeToSchedule,
  createAttendeeToSchedules,
  deleteAttendee,
  deleteAttendeeAbsences,
  deleteAttendeeToSchedule,
  enrollAttendeeToClass,
  enrollWaitingAttendee,
  fetchAttendeeToSchedules,
  fetchClassPublic,
  removeAttendeeSchedulesForClass,
  unenrollAttendeeFromSchedules,
  updateAttendee,
} from "./enrollmentOperations";
import { Attendee, AttendeeToSchedule, EnrollmentState } from "../types/types";

const initialState: EnrollmentState = {
  enrollment: null,
  class: null,
  loading: false,
  error: null,
};

const enrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAttendee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createAttendee.fulfilled,
        (state, action: PayloadAction<Attendee>) => {
          state.loading = false;
        }
      )
      .addCase(createAttendee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to create attendee";
      })
      .addCase(createAttendeeAdm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createAttendeeAdm.fulfilled,
        (state, action: PayloadAction<Attendee>) => {
          state.loading = false;
        }
      )
      .addCase(createAttendeeAdm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to create attendee";
      })
      .addCase(updateAttendee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateAttendee.fulfilled,
        (state, action: PayloadAction<Attendee>) => {
          state.loading = false;
        }
      )
      .addCase(updateAttendee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update attendee";
      })
      .addCase(deleteAttendee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteAttendee.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.loading = false;
        }
      )
      .addCase(deleteAttendee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to delete attendee";
      })
      .addCase(enrollAttendeeToClass.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(enrollAttendeeToClass.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(enrollAttendeeToClass.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to enroll attendee";
      })
      .addCase(fetchClassPublic.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClassPublic.fulfilled, (state, action) => {
        state.class = action.payload;
        state.loading = false;
      })
      .addCase(fetchClassPublic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch class";
      })
      .addCase(createAttendeeToSchedule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createAttendeeToSchedule.fulfilled,
        (state, action: PayloadAction<AttendeeToSchedule>) => {
          state.loading = false;
        }
      )
      .addCase(createAttendeeToSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to create attendee-to-schedule";
      })
      .addCase(createAttendeeToSchedules.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createAttendeeToSchedules.fulfilled,
        (state, action: PayloadAction<AttendeeToSchedule>) => {
          state.loading = false;
        }
      )
      .addCase(createAttendeeToSchedules.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to create attendee-to-schedule";
      })
      .addCase(fetchAttendeeToSchedules.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAttendeeToSchedules.fulfilled,
        (state, action: PayloadAction<AttendeeToSchedule[]>) => {
          state.loading = false;
        }
      )
      .addCase(fetchAttendeeToSchedules.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch attendee-to-schedules";
      })
      .addCase(deleteAttendeeToSchedule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteAttendeeToSchedule.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.loading = false;
        }
      )
      .addCase(deleteAttendeeToSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to delete attendee-to-schedule";
      })
      .addCase(createAttendeeAbsences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAttendeeAbsences.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createAttendeeAbsences.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to create attendee absences";
      })
      .addCase(deleteAttendeeAbsences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAttendeeAbsences.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteAttendeeAbsences.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to delete attendee absences";
      })
      .addCase(enrollWaitingAttendee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(enrollWaitingAttendee.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(enrollWaitingAttendee.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to enroll waiting attendee";
      })
      .addCase(removeAttendeeSchedulesForClass.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeAttendeeSchedulesForClass.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(removeAttendeeSchedulesForClass.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          "Failed to remove attendee schedules for class";
      })
      .addCase(unenrollAttendeeFromSchedules.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unenrollAttendeeFromSchedules.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(unenrollAttendeeFromSchedules.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to unenroll attendee from schedules";
      });
  },
});

export default enrollmentSlice.reducer;
