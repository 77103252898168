import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { RootState } from "../../redux/store";
import { Class, Schedule, Attendee } from "../../redux/types/types";
import { useLanguage } from "../LanguageProvider/LanguageProvider";

export default function UpcomingClasses() {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state: RootState) => state.auth.user);
  const { language } = useLanguage();

  const getNextScheduleWithActiveAttendees = (
    schedules: Schedule[],
    classStartDate?: Date
  ): Schedule | null => {
    const now = new Date();
    const lookAheadDays = 14;
    const upcomingSchedules: Schedule[] = [];

    schedules.forEach((schedule) => {
      if (schedule.repeatWeekly) {
        for (let i = 0; i < lookAheadDays; i++) {
          const date = new Date(now);
          date.setDate(now.getDate() + i);
          const dayOfWeek = date.getDay() === 0 ? 6 : date.getDay() - 1;

          if (schedule.dayOfWeek === dayOfWeek) {
            const [startHour, startMinute] = schedule.startTime
              .split(":")
              .map(Number);
            const scheduleDateTime = new Date(date);
            scheduleDateTime.setHours(startHour, startMinute, 0, 0);

            if (
              scheduleDateTime > now &&
              (!classStartDate || scheduleDateTime >= new Date(classStartDate))
            ) {
              upcomingSchedules.push({
                ...schedule,
                date: scheduleDateTime,
              });
            }
          }
        }
      } else {
        if (schedule.date) {
          const scheduleDateTime = new Date(schedule.date);
          const [startHour, startMinute] = schedule.startTime
            .split(":")
            .map(Number);
          scheduleDateTime.setHours(startHour, startMinute, 0, 0);

          if (
            scheduleDateTime > now &&
            (!classStartDate || scheduleDateTime >= new Date(classStartDate))
          ) {
            upcomingSchedules.push({
              ...schedule,
              date: scheduleDateTime,
            });
          }
        }
      }
    });

    upcomingSchedules.sort((a, b) => (a.date! > b.date! ? 1 : -1));

    for (const schedule of upcomingSchedules) {
      const scheduleDate = schedule.date!;
      const activeAttendees = schedule.AttendeeToSchedule?.filter((ats) => {
        const enrollDate = new Date(ats.createdAt);
        const unenrollDate = ats.unenrollDate
          ? new Date(ats.unenrollDate)
          : null;
        return (
          enrollDate <= scheduleDate &&
          (!unenrollDate || unenrollDate > scheduleDate)
        );
      });

      if (activeAttendees && activeAttendees.length > 0) {
        return schedule;
      }
    }

    return null;
  };

  const isClassActiveOnDate = (classItem: Class, date: Date): boolean => {
    const statusOnDate = classItem.statusHistory
      ?.filter((history) => new Date(history.date) <= date)
      .sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      )[0];

    return statusOnDate
      ? statusOnDate.status === "Active"
      : classItem.status === "Active";
  };

  const upcomingClasses = user.classes
    .map((classItem: Class) => ({
      ...classItem,
      nextSchedule: getNextScheduleWithActiveAttendees(
        classItem.schedules,
        classItem.startDate || new Date()
      ),
    }))
    .filter(
      (classItem) =>
        classItem.nextSchedule &&
        isClassActiveOnDate(classItem, classItem.nextSchedule.date!)
    )
    .sort((a, b) => {
      if (!a.nextSchedule || !b.nextSchedule) return 0;
      return a.nextSchedule.date!.getTime() - b.nextSchedule.date!.getTime();
    }) as (Class & { nextSchedule: Schedule })[];

  const getLocalizedValue = (
    obj: Record<string, string>,
    lang: string
  ): string => {
    return obj[lang] || obj["en"] || "";
  };

  const formatClassTime = (schedule: Schedule): string => {
    const scheduleDate = schedule.date!;
    return intl.formatDate(scheduleDate, {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const calculateAge = (birthdate: Date): number => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const renderAttendees = (classItem: Class & { nextSchedule: Schedule }) => {
    const scheduleDate = classItem.nextSchedule.date!;
    const attendees = classItem.nextSchedule.AttendeeToSchedule?.filter(
      (ats) => {
        const enrollDate = new Date(ats.createdAt);
        const unenrollDate = ats.unenrollDate
          ? new Date(ats.unenrollDate)
          : null;
        return (
          enrollDate <= scheduleDate &&
          (!unenrollDate || unenrollDate > scheduleDate)
        );
      }
    ).map((ats) => ats.attendee);

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2">
          <FormattedMessage
            id="upcomingClasses.attendees"
            defaultMessage="Attendees"
          />
          :
        </Typography>
        <List sx={{ p: 0 }}>
          {attendees?.map((attendee: Attendee, index: number) => (
            <ListItem key={attendee.id} sx={{ px: 0 }}>
              <ListItemText
                primary={`${index + 1}. ${attendee.firstName} ${
                  attendee.lastName
                }`}
                secondary={`${calculateAge(
                  attendee.birthdate
                )} ${intl.formatMessage({ id: "attendee.yearsOld" })}, ${
                  attendee.sex === "female"
                    ? intl.formatMessage({
                        id: "privateEnroll.female",
                      })
                    : attendee.sex === "male"
                    ? intl.formatMessage({
                        id: "privateEnroll.male",
                      })
                    : attendee.sex === "other"
                    ? intl.formatMessage({
                        id: "privateEnroll.other",
                      })
                    : attendee.sex
                }`}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        my: 4,
        mx: 2,
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto",
        pt: isMobile ? 5 : 0,
      }}
    >
      {upcomingClasses.length === 0 ? (
        <Typography>
          <FormattedMessage
            id="upcomingClasses.noClasses"
            defaultMessage="You have no upcoming classes."
          />
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {upcomingClasses.map((classItem) => (
            <Grid item xs={12} md={6} key={classItem.id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <CardContent sx={{ flex: 1 }}>
                  <Typography variant="h6" component="div">
                    {getLocalizedValue(classItem.title, language)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {intl.formatMessage({
                      id: "upcomingClasses.nextClass",
                      defaultMessage: "Next class",
                    })}
                    : {formatClassTime(classItem.nextSchedule!)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {intl.formatMessage({
                      id: "upcomingClasses.instructor",
                      defaultMessage: "Instructor",
                    })}
                    :{" "}
                    {classItem.instructors.map(
                      (i) =>
                        `${i.instructor.firstname} ${i.instructor.lastname} `
                    )}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {intl.formatMessage({
                      id: "upcomingClasses.location",
                      defaultMessage: "Location",
                    })}
                    : {getLocalizedValue(classItem.location.name, language)}
                  </Typography>
                </CardContent>
                {!isMobile && (
                  <Box
                    sx={{
                      width: "30%",
                      borderLeft: "1px solid",
                      borderColor: "divider",
                      p: 2,
                    }}
                  >
                    {renderAttendees(classItem)}
                  </Box>
                )}
                {isMobile && (
                  <Box sx={{ p: 2 }}>{renderAttendees(classItem)}</Box>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}
