import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
  LinearProgress,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  Class as ClassIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  Event as EventIcon,
  Schedule as ScheduleIcon,
  Groups as GroupsIcon,
  PendingActions as PendingActionsIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import getLPTheme from "../../../helpers/getLPTheme";
import { useLanguage } from "../../LanguageProvider/LanguageProvider";
import { AppDispatch, RootState } from "../../../redux/store";
import { logOut } from "../../../redux/auth/authOperations";

const drawerWidth = 240;

export default function DashboardLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const { language, setLanguage } = useLanguage();
  const isInstructor = useSelector(
    (state: RootState) => state.auth.isInstructor
  );
  const loading = useSelector((state: RootState) => state.auth.loading);

  const [mode, setMode] = React.useState<"light" | "dark">(
    localStorage.getItem("mode") === "dark" ? "dark" : "light"
  );
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleColorMode = React.useCallback(() => {
    setMode((prevMode) => {
      const newMode = prevMode === "light" ? "dark" : "light";
      localStorage.setItem("mode", newMode);
      return newMode;
    });
  }, []);

  const LPtheme = React.useMemo(() => createTheme(getLPTheme(mode)), [mode]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = async () => {
    dispatch(logOut());
  };

  if (loading && isInstructor === null) {
    return <LinearProgress />;
  }

  const navItems = isInstructor
    ? [
        {
          path: "profile",
          labelId: "homeHeader.profile",
          icon: <PersonIcon />,
        },
        {
          path: "my-classes",
          labelId: "homeHeader.myClasses",
          icon: <ClassIcon />,
        },
        {
          path: "upcoming-classes",
          labelId: "homeHeader.upcomingClasses",
          icon: <ScheduleIcon />,
        },
        {
          path: "my-schedules",
          labelId: "homeHeader.myShedules",
          icon: <EventIcon />,
        },
        {
          path: "attd",
          labelId: "mySchedules.attendance",
          icon: <GroupsIcon />,
        },
      ]
    : [
        {
          path: "profile",
          labelId: "homeHeader.profile",
          icon: <PersonIcon />,
        },
        {
          path: "attd",
          labelId: "homeHeader.attendees",
          icon: <GroupIcon />,
        },
        {
          path: "my-classes",
          labelId: "homeHeader.myClasses",
          icon: <ClassIcon />,
        },
        {
          path: "upcoming-classes",
          labelId: "homeHeader.upcomingClasses",
          icon: <ScheduleIcon />,
        },
        {
          path: "my-schedules",
          labelId: "homeHeader.myShedules",
          icon: <EventIcon />,
        },
      ];

  const drawer = (
    <Box sx={{ overflow: "auto" }}>
      <List>
        {navItems.map((item) => (
          <ListItem
            button
            key={item.path}
            selected={location.pathname === `/${item.path}`}
            onClick={() => navigate(`/${item.path}`)}
            sx={{
              backgroundColor:
                location.pathname === `/${item.path}`
                  ? "rgba(0, 0, 0, 0.08)"
                  : "inherit",
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  id={item.labelId}
                  defaultMessage={item.path.replace("-", " ")}
                />
              }
            />
          </ListItem>
        ))}
        {!isInstructor && (
          <ListItem
            button
            key={"timetableLink"}
            onClick={() =>
              window.open(
                intl.locale === "uk"
                  ? "https://www.ukraine-reborn.ch/uk/calendar-uk/"
                  : intl.locale === "en"
                  ? "https://www.ukraine-reborn.ch/en/calendar-en/"
                  : "https://www.ukraine-reborn.ch/calendar/",
                "_blank"
              )
            }
          >
            <ListItemIcon>{<PendingActionsIcon />}</ListItemIcon>
            <ListItemText
              primary={<FormattedMessage id={"homeHeader.timetable"} />}
            />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={LPtheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#2196f3",
          }}
        >
          <Toolbar>
            {(isMobile || isTablet) && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              <FormattedMessage
                id={
                  isInstructor ? "dashboard.instructorTitle" : "dashboard.title"
                }
                defaultMessage={
                  isInstructor ? "Instructor Dashboard" : "User Dashboard"
                }
              />
            </Typography>
            <Select
              value={language}
              onChange={(e) =>
                setLanguage(e.target.value as "en" | "fr" | "uk")
              }
              size="small"
              sx={{
                mr: 2,
                color: "white",
                "& .MuiSelect-icon": { color: "white" },
              }}
            >
              <MenuItem value="en">EN</MenuItem>
              <MenuItem value="fr">FR</MenuItem>
              <MenuItem value="uk">UK</MenuItem>
            </Select>
            <Tooltip
              title={intl.formatMessage({
                id: "dashboard.toggleTheme",
                defaultMessage: "Toggle theme",
              })}
            >
              <IconButton color="inherit" onClick={toggleColorMode}>
                {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage({ id: "dashboard.logout" })}>
              <IconButton color="inherit" onClick={handleLogout}>
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Drawer
          variant={isMobile || isTablet ? "temporary" : "permanent"}
          open={isMobile || isTablet ? drawerOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          {drawer}
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
