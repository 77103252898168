import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Notiflix from "notiflix";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  fetchInstructors,
  deleteInstructor,
  updateInstructor,
  resetInstructorPassword,
  createInstructor,
} from "../../../redux/admin/adminOperations";
import { Instructor } from "../../../redux/types/types";

type Order = "asc" | "desc";

export const useInstructorManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const { instructors, totalInstructors, loading, error } = useSelector(
    (state: RootState) => state.admin
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [instructorToDelete, setInstructorToDelete] = useState<number | null>(
    null
  );
  const [instructorToEdit, setInstructorToEdit] = useState<Instructor | null>(
    null
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState<keyof Instructor>("id");
  const [order, setOrder] = useState<Order>("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [createError, setCreateError] = useState<string | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const fetchInstructorData = useCallback(() => {
    dispatch(
      fetchInstructors({
        page: page + 1,
        limit: rowsPerPage,
        sortBy: orderBy,
        sortOrder: order,
        search: searchTerm,
      })
    );
  }, [dispatch, page, rowsPerPage, orderBy, order, searchTerm]);

  const handleDeleteInstructor = useCallback((instructorId: number) => {
    setInstructorToDelete(instructorId);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDeleteInstructor = useCallback(() => {
    if (instructorToDelete) {
      dispatch(deleteInstructor(instructorToDelete))
        .unwrap()
        .then(() => {
          fetchInstructorData();
          setDeleteDialogOpen(false);
          setInstructorToDelete(null);
          setDeleteError(null);
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.deleteInstructorSuccess" })
          );
        })
        .catch((error) => {
          let errorKey = "error.failedToDeleteInstructor";
          if (error.message === "Instructor not found.") {
            errorKey = "error.instructorNotFound";
          } else if (
            error.message ===
            "Cannot delete instructor because they are associated with other records."
          ) {
            errorKey = "error.instructorInUse";
          } else if (error.message === "An unexpected error occurred.") {
            errorKey = "error.unexpectedError";
          }
          setDeleteError(errorKey);
        });
    }
  }, [instructorToDelete, dispatch, fetchInstructorData, intl]);

  const handleEditInstructor = useCallback((instructor: Instructor) => {
    setInstructorToEdit(instructor);
    setEditDialogOpen(true);
  }, []);

  const handleUpdateInstructor = useCallback(
    (instructor: Instructor) => {
      if (instructor && instructorToEdit && instructorToEdit.id) {
        dispatch(
          updateInstructor({
            instructorId: instructorToEdit.id,
            instructorData: instructor,
          })
        )
          .unwrap()
          .then(() => {
            fetchInstructorData();
            setEditDialogOpen(false);
            setInstructorToEdit(null);
            setUpdateError(null);
            Notiflix.Notify.success(
              intl.formatMessage({ id: "admin.editInstructorSuccess" })
            );
          })
          .catch((error) => {
            let errorKey = "error.failedToUpdateInstructor";
            if (error === "Invalid instructor ID provided.") {
              errorKey = "error.invalidInstructorId";
            } else if (error === "The email provided is already in use.") {
              errorKey = "error.emailInUse";
            } else if (error === "Instructor not found.") {
              errorKey = "error.instructorNotFound";
            } else if (error === "An unexpected error occurred.") {
              errorKey = "error.unexpectedError";
            }
            setUpdateError(errorKey);
          });
      }
    },
    [instructorToEdit, dispatch, fetchInstructorData, intl]
  );

  const onInstructorUpdate = async (instructor: Instructor) => {
    await setInstructorToEdit(instructor);
    await handleUpdateInstructor(instructor);
  };

  const handleCreateInstructor = useCallback(
    (instructorData: Omit<Instructor, "id" | "role">) => {
      dispatch(
        createInstructor({
          instructorData: { ...instructorData },
          language: intl.locale,
        })
      )
        .unwrap()
        .then(() => {
          fetchInstructorData();
          setCreateDialogOpen(false);
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.createInstructorSuccess" })
          );
        })
        .catch((error) => {
          let errorKey = "error.failedToCreateInstructor";
          if (error === "The email provided is already in use.") {
            errorKey = "error.emailInUse";
          } else if (error === "An unexpected error occurred.") {
            errorKey = "error.unexpectedError";
          }
          setCreateError(errorKey);
        });
    },
    [dispatch, fetchInstructorData, intl]
  );

  const handleResetPassword = useCallback(
    (instructorId: number) => {
      dispatch(resetInstructorPassword({ instructorId, language: intl.locale }))
        .unwrap()
        .then(() => {
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.dashboard.passwordResetSuccess" })
          );
        })
        .catch((error) => {
          Notiflix.Notify.failure("Failed to reset password:", error);
        });
    },
    [dispatch, intl]
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const handleRequestSort = useCallback(
    (property: keyof Instructor) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(0);
    },
    []
  );

  return {
    instructors,
    totalInstructors,
    loading,
    error,
    deleteDialogOpen,
    editDialogOpen,
    createDialogOpen,
    instructorToDelete,
    instructorToEdit,
    page,
    rowsPerPage,
    orderBy,
    order,
    searchTerm,
    updateError,
    createError,
    deleteError,
    setUpdateError,
    fetchInstructorData,
    handleDeleteInstructor,
    confirmDeleteInstructor,
    handleEditInstructor,
    handleUpdateInstructor,
    handleCreateInstructor,
    handleResetPassword,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSearchChange,
    setDeleteDialogOpen,
    setEditDialogOpen,
    setCreateDialogOpen,
    setInstructorToEdit,
    setDeleteError,
    setCreateError,
    onInstructorUpdate,
  };
};
