import axios from "axios";
import api from "./axiosInstance";
import Cookies from "js-cookie";

export const confirmEmailChangeApi = async (token: string) => {
  const response = await api.post("/auth/confirm-email-change", { token });
  return response.data;
};

export const forgotPasswordApi = async (email: string, language: string) => {
  const response = await api.post(
    `/auth/forgot-password?language=${language}`,
    { email }
  );
  return response.data;
};

export const resetPasswordApi = async (token: string, password: string) => {
  const response = await api.post("/auth/reset-password", { token, password });
  return response.data;
};

export const registerUserApi = async (userData: {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  phone: string;
  dataUsageAgreed: boolean;
  photoAgreed: boolean;
}) => {
  const response = await api.post("/auth/register", userData);
  return response.data;
};

export const loginUserApi = async (loginData: {
  email: string;
  password: string;
}) => {
  const response = await api.post("/auth/login", loginData);
  return response.data;
};

export const loginInstructorApi = async (loginData: {
  email: string;
  password: string;
}) => {
  const response = await api.post("/auth/instructor/login", loginData);
  return response.data;
};

export const getProfileApi = async () => {
  const response = await api.get("/auth/profile");
  return response.data;
};

export const updateProfileApi = async (
  profileData: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
  },
  language: string
) => {
  const response = await api.patch(
    `/auth/profile?language=${language}`,
    profileData
  );
  return response.data;
};

export const googleAuthApi = async (token: string) => {
  const response = await api.post("/auth/google", { token });
  return response.data;
};

export const appleAuthApi = async (body: any) => {
  const response = await api.post("/auth/apple", { ...body });
  return response.data;
};

export const loginAdminApi = async (credentials: {
  email: string;
  password: string;
}) => {
  const response = await api.post("/auth/admin/login", credentials);
  return response.data;
};

const changePasswordAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

changePasswordAxios.interceptors.request.use(
  (config) => {
    const token = Cookies.get("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const changePasswordApi = async (
  oldPassword: string,
  newPassword: string
) => {
  const response = await changePasswordAxios.patch("/auth/change-password", {
    oldPassword,
    newPassword,
  });
  return response.data;
};
