import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createAttendeeAbsenceApi,
  createAttendeeApi,
  createAttendeeToClassApi,
  createAttendeeToScheduleApi,
  createAttendeeToSchedulesApi,
  deleteAttendeeAbsenceApi,
  deleteAttendeeApi,
  deleteAttendeeToScheduleApi,
  enrollWaitingAttendeeApi,
  fetchAttendeesByUserIdApi,
  fetchAttendeeToSchedulesApi,
  fetchClassPublicApi,
  getAttendeeToScheduleApi,
  removeAttendeeSchedulesForClassApi,
  unenrollAttendeeFromSchedulesApi,
  updateAttendeeApi,
  updateAttendeeToScheduleApi,
} from "../../utils/api/attendees";
import { RootState } from "../store";
import { Attendee, AttendeeToSchedule, Class } from "../types/types";

export const createAttendee = createAsyncThunk<
  Attendee,
  Omit<Attendee, "id" | "userId">,
  { state: RootState }
>(
  "enrollment/createAttendee",
  async (attendeeData, { getState, rejectWithValue }) => {
    try {
      const { user } = getState().auth;
      if (!user.id) {
        throw new Error("User not authenticated");
      }
      const response = await createAttendeeApi({
        ...attendeeData,
        userId: user.id,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  }
);

export const createAttendeeAdm = createAsyncThunk<
  Attendee,
  { attendee: Omit<Attendee, "id" | "userId">; userId: number },
  { state: RootState }
>(
  "enrollment/createAttendeeAdm",
  async ({ attendee, userId }, { rejectWithValue }) => {
    try {
      const response = await createAttendeeApi({
        ...attendee,
        userId: userId,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateAttendee = createAsyncThunk<
  Attendee,
  { attendeeId: number; attendeeData: Partial<Attendee> },
  { state: RootState }
>(
  "enrollment/updateAttendee",
  async ({ attendeeId, attendeeData }, { getState }) => {
    const { user } = getState().auth;
    if (!user.id) {
      throw new Error("User not authenticated");
    }
    const response = await updateAttendeeApi(attendeeId, attendeeData);
    return response.data;
  }
);

export const deleteAttendee = createAsyncThunk<
  number,
  number,
  { state: RootState }
>("enrollment/deleteAttendee", async (attendeeId, { getState }) => {
  const { user } = getState().auth;
  if (!user.id) {
    throw new Error("User not authenticated");
  }
  await deleteAttendeeApi(attendeeId);
  return attendeeId;
});

export const enrollAttendeeToClass = createAsyncThunk<
  void,
  { attendeeId: number; classId: number },
  { rejectValue: string }
>(
  "enrollment/enrollAttendeeToClass",
  async ({ attendeeId, classId }, { rejectWithValue }) => {
    try {
      await createAttendeeToClassApi({ attendeeId, classId });
    } catch (error: any) {
      return await rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchUserAttendees = createAsyncThunk<
  Attendee[],
  void,
  { state: RootState }
>("enrollment/fetchUserAttendees", async (_, { getState }) => {
  const { user } = getState().auth;
  if (!user.id) {
    throw new Error("User not authenticated");
  }
  const response = await fetchAttendeesByUserIdApi(user.id);
  return response.data;
});

export const fetchClassPublic = createAsyncThunk<Class, number>(
  "enrollment/fetchClassPublic",
  async (classId) => {
    const response = await fetchClassPublicApi(classId);
    return response.data;
  }
);

export const createAttendeeToSchedule = createAsyncThunk<
  AttendeeToSchedule,
  { attendeeId: number; scheduleId: number },
  { state: RootState }
>("enrollment/createAttendeeToSchedule", async (data, { getState }) => {
  const { user } = getState().auth;
  if (!user.id) {
    throw new Error("User not authenticated");
  }
  const response = await createAttendeeToScheduleApi(data);
  return response.data;
});

export const createAttendeeToSchedules = createAsyncThunk<
  AttendeeToSchedule,
  { data: { attendeeId: number; scheduleIds: number[] }; language: string },
  { state: RootState }
>(
  "enrollment/createAttendeeToSchedules",
  async ({ data, language }, { rejectWithValue, getState }) => {
    try {
      const { user } = getState().auth;
      if (!user.id) {
        throw new Error("User not authenticated");
      }
      const response = await createAttendeeToSchedulesApi(data, language);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const fetchAttendeeToSchedules = createAsyncThunk<
  AttendeeToSchedule[],
  void,
  { state: RootState }
>("enrollment/fetchAttendeeToSchedules", async (_, { getState }) => {
  const { user } = getState().auth;
  if (!user.id) {
    throw new Error("User not authenticated");
  }
  const response = await fetchAttendeeToSchedulesApi();
  return response.data;
});

export const getAttendeeToSchedule = createAsyncThunk<
  AttendeeToSchedule,
  number,
  { state: RootState }
>("enrollment/getAttendeeToSchedule", async (id, { getState }) => {
  const { user } = getState().auth;
  if (!user.id) {
    throw new Error("User not authenticated");
  }
  const response = await getAttendeeToScheduleApi(id);
  return response.data;
});

export const updateAttendeeToSchedule = createAsyncThunk<
  AttendeeToSchedule,
  { id: number; data: { attendeeId?: number; scheduleId?: number } },
  { state: RootState }
>("enrollment/updateAttendeeToSchedule", async ({ id, data }, { getState }) => {
  const { user } = getState().auth;
  if (!user.id) {
    throw new Error("User not authenticated");
  }
  const response = await updateAttendeeToScheduleApi(id, data);
  return response.data;
});

export const deleteAttendeeToSchedule = createAsyncThunk<
  number,
  number,
  { state: RootState }
>("enrollment/deleteAttendeeToSchedule", async (id, { getState }) => {
  const { user } = getState().auth;
  if (!user.id) {
    throw new Error("User not authenticated");
  }
  await deleteAttendeeToScheduleApi(id);
  return id;
});

export const createAttendeeAbsences = createAsyncThunk<
  { message: string; count: number },
  { scheduleId: number; date: Date; attendeeIds: number[] },
  { state: RootState }
>("enrollment/createAttendeeAbsence", async (data, { getState }) => {
  const { user } = getState().auth;
  if (!user?.id) {
    throw new Error("User not authenticated");
  }
  const response = await createAttendeeAbsenceApi(data);
  return response.data;
});

export const deleteAttendeeAbsences = createAsyncThunk<
  { message: string; count: number },
  { scheduleId: number; date: Date; attendeeIds: number[] },
  { state: RootState }
>("enrollment/deleteAttendeeAbsence", async (data, { getState }) => {
  const { user } = getState().auth;
  if (!user?.id) {
    throw new Error("User not authenticated");
  }
  const response = await deleteAttendeeAbsenceApi(data);
  return response.data;
});

export const enrollWaitingAttendee = createAsyncThunk<
  { message: string },
  { waitingAttendeeId: number; language: string },
  { state: RootState }
>(
  "enrollment/enrollWaitingAttendee",
  async ({ waitingAttendeeId, language }, { getState }) => {
    const { user } = getState().auth;
    if (!user.id) {
      throw new Error("User not authenticated");
    }
    const response = await enrollWaitingAttendeeApi(
      waitingAttendeeId,
      language
    );
    return response.data;
  }
);

export const removeAttendeeSchedulesForClass = createAsyncThunk<
  { message: string },
  { attendeeId: number; classId: number },
  { state: RootState }
>(
  "enrollment/removeAttendeeSchedulesForClass",
  async ({ attendeeId, classId }, { getState }) => {
    const { user } = getState().auth;
    if (!user.id) {
      throw new Error("User not authenticated");
    }
    const response = await removeAttendeeSchedulesForClassApi(
      attendeeId,
      classId
    );
    return response.data;
  }
);

export const unenrollAttendeeFromSchedules = createAsyncThunk<
  { message: string },
  { attendeeId: number; scheduleIds: number[] },
  { state: RootState }
>(
  "enrollment/unenrollAttendeeFromSchedules",
  async ({ attendeeId, scheduleIds }, { getState }) => {
    const { user } = getState().auth;
    if (!user.id) {
      throw new Error("User not authenticated");
    }
    const response = await unenrollAttendeeFromSchedulesApi({
      attendeeId,
      scheduleIds,
    });
    return response.data;
  }
);
