import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Avatar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Attendee, Schedule } from "../../../redux/types/types";
import { getActiveAttendees } from "../MySchedules";

interface EventDialogProps {
  isOpen: boolean;
  onClose: () => void;
  event: {
    id: string;
    title: string;
    start: Date;
    end: Date;
    extendedProps: {
      location: string;
      type: string;
      attendees: Attendee[];
      schedule: Schedule;
    };
  } | null;
}

export default function EventDialogNonInstructor({
  isOpen,
  onClose,
  event,
}: EventDialogProps) {
  const theme = useTheme();
  const intl = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const generateAttendeeColor = (id: number): string => {
    const hue = (id * 137.508) % 360;
    return theme.palette.mode === "dark"
      ? `hsl(${hue}, 100%, 25%)`
      : `hsl(${hue}, 100%, 75%)`;
  };

  if (!event) return null;

  const weekdays = [
    intl.formatMessage({ id: "admin.dashboard.sunday" }),
    intl.formatMessage({ id: "admin.dashboard.monday" }),
    intl.formatMessage({ id: "admin.dashboard.tuesday" }),
    intl.formatMessage({ id: "admin.dashboard.wednesday" }),
    intl.formatMessage({ id: "admin.dashboard.thursday" }),
    intl.formatMessage({ id: "admin.dashboard.friday" }),
    intl.formatMessage({ id: "admin.dashboard.saturday" }),
  ];

  const formatTime = (date: Date) => {
    return intl.formatTime(date, {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  };

  if (!event.extendedProps.schedule.date) {
    event.extendedProps.attendees = getActiveAttendees(
      event.start,
      event.extendedProps.schedule
    );
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle sx={{ pr: 6 }}>
        {event.title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          <FormattedMessage id="mySchedules.date" defaultMessage="Date" />:{" "}
          {intl.formatDate(event.start, {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id="mySchedules.weekday" defaultMessage="Weekday" />
          : {weekdays[event.start.getDay()]}
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id="mySchedules.time" defaultMessage="Time" />:{" "}
          {formatTime(event.start)} - {formatTime(event.end)}
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="mySchedules.location"
            defaultMessage="Location"
          />
          : {event.extendedProps.location}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <FormattedMessage id="mySchedules.type" defaultMessage="Type" />:{" "}
          {event.extendedProps.type}
        </Typography>
        <Typography variant="h6" sx={{ mb: 1 }}>
          <FormattedMessage
            id="mySchedules.attendees"
            defaultMessage="Attendees"
          />
          :
        </Typography>
        <List>
          {event.extendedProps.attendees.map((attendee: Attendee) => (
            <ListItem key={attendee.id}>
              <Avatar
                sx={{
                  bgcolor: generateAttendeeColor(attendee.id),
                  mr: 2,
                }}
              >
                {attendee.firstName[0]}
              </Avatar>
              <ListItemText
                primary={`${attendee.firstName} ${attendee.lastName}`}
                secondary={attendee.email}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
