import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Grid,
  Autocomplete,
} from "@mui/material";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  getClassReport,
  fetchInstructors,
  fetchClasses,
  fetchLocations,
  fetchTypes,
} from "../../../../redux/admin/adminOperations";
import {
  Instructor,
  Class,
  Type,
  Location,
  Attendee,
} from "../../../../redux/types/types";
import * as XLSX from "xlsx";
import AttendeeDialog from "./AttendeeDialog/AttendeeDialog";
import {
  Language,
  useLanguage,
} from "../../../LanguageProvider/LanguageProvider";

export default function AttendanceReports() {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const { language } = useLanguage();
  const { instructors, classes, types, locations, loading } = useSelector(
    (state: RootState) => state.admin
  );
  const { attendeesReports } = useSelector(
    (state: RootState) => state.admin.reports
  );
  const [selectedInstructor, setSelectedInstructor] =
    useState<Instructor | null>(null);
  const [selectedClass, setSelectedClass] = useState<Class | null>(null);
  const [selectedType, setSelectedType] = useState<Type | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedAttendee, setSelectedAttendee] = useState<Attendee | null>(
    null
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(
      fetchInstructors({
        page: 1,
        limit: 1000,
        sortBy: "id",
        sortOrder: "asc",
        search: "",
      })
    );
    dispatch(
      fetchClasses({
        page: 1,
        limit: 1000,
        sortBy: "id",
        sortOrder: "asc",
        search: "",
      })
    );
    dispatch(
      fetchLocations({
        page: 1,
        limit: 1000,
        sortBy: "id",
        sortOrder: "asc",
        search: "",
      })
    );
    dispatch(
      fetchTypes({
        page: 1,
        limit: 1000,
        sortBy: "id",
        sortOrder: "asc",
        search: "",
      })
    );
  }, [dispatch]);

  const filteredClasses = useMemo(() => {
    if (!selectedInstructor) return classes;
    return classes.filter((c) =>
      c.instructors.find((i) => i.instructor.id === selectedInstructor.id)
    );
  }, [classes, selectedInstructor]);

  const filteredLocations = useMemo(() => {
    if (!selectedInstructor && !selectedClass) return locations;
    if (selectedClass) {
      return [selectedClass.location];
    }
    return locations.filter((l) =>
      filteredClasses.some((c) => c.location.id === l.id)
    );
  }, [locations, filteredClasses, selectedInstructor, selectedClass]);

  const filteredTypes = useMemo(() => {
    if (!selectedInstructor && !selectedClass) return types;
    if (selectedClass) {
      return [selectedClass.type];
    }
    return types.filter((t) => filteredClasses.some((c) => c.type.id === t.id));
  }, [types, filteredClasses, selectedInstructor, selectedClass]);

  const handleInstructorChange = (value: Instructor | null) => {
    setSelectedInstructor(value);
    setSelectedClass(null);
    setSelectedLocation(null);
    setSelectedType(null);
  };

  const handleClassChange = (value: Class | null) => {
    setSelectedClass(value);
    setSelectedLocation(value ? value.location : null);
    setSelectedType(value ? value.type : null);
  };

  const handleGenerateReport = () => {
    dispatch(
      getClassReport({
        instructorId: selectedInstructor?.id || undefined,
        classId: selectedClass?.id || undefined,
        fromDate: fromDate || undefined,
        toDate: toDate || undefined,
        typeId: selectedType?.id || undefined,
        locationId: selectedLocation?.id || undefined,
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedSessions = useMemo(() => {
    if (!attendeesReports) return [];
    return attendeesReports
      .flatMap((report) =>
        report.sessions.flatMap((session) =>
          session.presentAttendees.map((attendee) => ({
            attendee,
            session,
            class: report.class,
          }))
        )
      )
      .sort((a, b) => {
        const dateA = new Date(a.session.date);
        const dateB = new Date(b.session.date);
        if (dateA.getTime() !== dateB.getTime()) {
          return dateB.getTime() - dateA.getTime();
        }
        return b.session.schedule.startTime.localeCompare(
          a.session.schedule.startTime
        );
      });
  }, [attendeesReports]);

  const paginatedSessions = sortedSessions.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  const getFormattedDateTime = () => {
    const now = new Date();
    return now.toISOString().replace(/[:.]/g, "-").slice(0, -5);
  };

  const getFormattedFilters = () => {
    const filters = [];
    if (selectedInstructor) filters.push(`instructor-${selectedInstructor.id}`);
    if (selectedClass) filters.push(`class-${selectedClass.id}`);
    if (selectedType) filters.push(`type-${selectedType.id}`);
    if (selectedLocation) filters.push(`location-${selectedLocation.id}`);
    if (fromDate) filters.push(`from-${fromDate}`);
    if (toDate) filters.push(`to-${toDate}`);
    return filters.join("_");
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      sortedSessions.map((item) => ({
        Attendee: `${item.attendee.firstName} ${item.attendee.lastName}`,
        "Attendee Email": item.attendee.email,
        "Attendee Birthdate": intl.formatDate(
          new Date(item.attendee.birthdate)
        ),
        "Attendee Sex": item.attendee.sex,
        "Attendee Phone": item.attendee.phone,
        "Attendee Postal Code": item.attendee.postalCode,
        "Attendee Permit Status":
          (item.attendee.permisStatus === "PermisS" &&
            intl.formatMessage({
              id: "attendee.permisStatus.permisS",
            })) ||
          (item.attendee.permisStatus === "Other" &&
            intl.formatMessage({
              id: "attendee.permisStatus.other",
            })),
        "User Name": item.attendee.user
          ? `${item.attendee.user.firstname} ${item.attendee.user.lastname}`
          : "",
        "User Email": item.attendee.user ? item.attendee.user.email : "",
        Class: item.class.title.fr,
        Date: intl.formatDate(new Date(item.session.date), {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
        Schedule: `${item.session.schedule.startTime} - ${item.session.schedule.endTime}`,
        Instructor: `${item.class.instructors
          .map((i) => `${i.instructor.firstname} ${i.instructor.lastname}`)
          .join(", ")}`,
        Location: item.class.location.name.fr,
        Type: item.class.type.name.fr,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    const filters = getFormattedFilters();
    const fileName = `class_report_${getFormattedDateTime()}${
      filters ? "_" + filters : ""
    }.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  const handleAttendeeClick = (attendee: Attendee) => {
    setSelectedAttendee(attendee);
    setDialogOpen(true);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        <FormattedMessage
          id="admin.dashboard.reports.title"
          defaultMessage="Generate Reports"
        />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              options={instructors}
              getOptionLabel={(option: Instructor) =>
                `${option.firstname} ${option.lastname}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "admin.dashboard.instructor",
                    defaultMessage: "Instructor",
                  })}
                  fullWidth
                />
              )}
              value={selectedInstructor}
              onChange={(event, newValue) => handleInstructorChange(newValue)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              options={filteredClasses}
              getOptionLabel={(option: Class) =>
                option.title[language] ||
                option.title[
                  process.env.REACT_APP_DEFAULT_LANGUAGE as Language
                ] ||
                option.title.fr ||
                option.title.en ||
                option.title.uk
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "admin.dashboard.class",
                    defaultMessage: "Class",
                  })}
                  fullWidth
                />
              )}
              value={selectedClass}
              onChange={(event, newValue) => handleClassChange(newValue)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              options={filteredTypes}
              getOptionLabel={(option: Type) =>
                option.name[language] ||
                option.name[
                  process.env.REACT_APP_DEFAULT_LANGUAGE as Language
                ] ||
                option.name.fr ||
                option.name.en ||
                option.name.uk
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "admin.dashboard.type",
                    defaultMessage: "Type",
                  })}
                  fullWidth
                />
              )}
              value={selectedType}
              onChange={(event, newValue) => setSelectedType(newValue)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              options={filteredLocations}
              getOptionLabel={(option: Location) =>
                option.name[language] ||
                option.name[
                  process.env.REACT_APP_DEFAULT_LANGUAGE as Language
                ] ||
                option.name.fr ||
                option.name.en ||
                option.name.uk
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "admin.dashboard.location",
                    defaultMessage: "Location",
                  })}
                  fullWidth
                />
              )}
              value={selectedLocation}
              onChange={(event, newValue) => setSelectedLocation(newValue)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label={intl.formatMessage({
                id: "admin.dashboard.reports.fromDate",
                defaultMessage: "From Date",
              })}
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label={intl.formatMessage({
                id: "admin.dashboard.reports.toDate",
                defaultMessage: "To Date",
              })}
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              onClick={handleGenerateReport}
              disabled={loading}
              fullWidth
              sx={{ height: "100%" }}
            >
              <FormattedMessage
                id="admin.dashboard.reports.generate"
                defaultMessage="Generate Report"
              />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="outlined"
              onClick={handleExportToExcel}
              disabled={!attendeesReports || attendeesReports.length === 0}
              fullWidth
              sx={{ height: "100%" }}
            >
              <FormattedMessage
                id="admin.dashboard.reports.exportExcel"
                defaultMessage="Export to Excel"
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {attendeesReports && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    id="admin.dashboard.attendee"
                    defaultMessage="Attendee"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="admin.dashboard.class"
                    defaultMessage="Class"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="admin.dashboard.date"
                    defaultMessage="Date"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="admin.dashboard.schedule"
                    defaultMessage="Schedule"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="admin.dashboard.instructor"
                    defaultMessage="Instructor"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="admin.dashboard.location"
                    defaultMessage="Location"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="admin.dashboard.type"
                    defaultMessage="Type"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedSessions.map((item, index) => (
                <TableRow key={`${item.class.id}-${item.attendee.id}-${index}`}>
                  <TableCell>
                    <Button onClick={() => handleAttendeeClick(item.attendee)}>
                      {`${item.attendee.firstName} ${item.attendee.lastName}`}
                    </Button>
                  </TableCell>
                  <TableCell>{item.class.title.en}</TableCell>
                  <TableCell>
                    {intl.formatDate(new Date(item.session.date), {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </TableCell>
                  <TableCell>{`${item.session.schedule.startTime} - ${item.session.schedule.endTime}`}</TableCell>
                  <TableCell>{`${item.class.instructors
                    .map(
                      (i) =>
                        `${i.instructor.firstname} ${i.instructor.lastname}`
                    )
                    .join(", ")}`}</TableCell>
                  <TableCell>
                    {item.class.location.name.fr || item.class.location.name.en}
                  </TableCell>
                  <TableCell>
                    {item.class.type.name.fr || item.class.type.name.en}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedSessions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={intl.formatMessage({
              id: "admin.dashboard.rowsPerPage",
              defaultMessage: "Rows per page:",
            })}
          />
        </TableContainer>
      )}
      <AttendeeDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        attendee={selectedAttendee}
      />
    </Box>
  );
}
