import React from "react";
import { FieldProps } from "formik";
import { Box } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CustomTextField from "../components/CustomTextField/CustomTextField";

interface PhoneFieldProps extends FieldProps {
  label?: string;
  invalidPhoneNumberMessage?: string;
}

const PhoneField: React.FC<PhoneFieldProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  invalidPhoneNumberMessage,
  ...props
}) => {
  const errorValue = errors ? errors[field.name] : null;
  const error =
    touched && touched[field.name] && typeof errorValue === "string"
      ? errorValue
      : undefined;

  return (
    <div style={{ margin: "16px 0" }}>
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="US"
        value={field.value}
        onChange={(value) => setFieldValue(field.name, value)}
        inputComponent={CustomTextField as any}
        id={field.name}
        error={Boolean(error)}
        {...props}
      />
      {error && (
        <Box component="span" sx={{ color: "error.main", fontSize: "0.75rem" }}>
          {invalidPhoneNumberMessage || error}
        </Box>
      )}
    </div>
  );
};

export default PhoneField;
